import {
  ReferralMetadata,
  ReferralActionType,
  UserForm,
  userFormTypes,
  PartnerCentralUsers,
  LabraSettingObject,
  getReferredBy,
} from './actions'
import { isEmpty, orderBy } from 'lodash'
import {
  changeDefaultValue,
  changeReadOnlyBaseOnFieldAndValue,
  mergeDefaultValues,
} from '../../../common/utils/helperFunctions'
import {
  FormField,
  Categories,
} from '@labrav/react-components/lib/@types/types/forms'
import { referralAPIToForm } from '../../../oppsync/modules/CoSell/ReferralForm/actions'

export interface FormDefaultValues {
  disableInCreation: boolean
  hideInCreation: boolean
  disableInUpdate: boolean
  hideInUpdate: boolean
  isRequired: boolean
  info?: string
  validationType?: string
  maxLength?: number
  minLength?: number
  options?: string[]
  defaultValue: unknown
  isReadOnly: boolean
  isHidden: boolean
}
export interface UserFormState {
  customData?: UserForm
  defaultValues: FormDefaultValues
  loading?: boolean
  loadingSubmit?: boolean
  submitted?: boolean
  trackId?: string
  marketplaceUrl?: string
  errMess: unknown
}

export const initialState: UserFormState = {
  customData: undefined,
  defaultValues: {
    disableInCreation: false,
    hideInCreation: false,
    disableInUpdate: false,
    hideInUpdate: false,
    isRequired: false,
    info: undefined,
    validationType: undefined,
    maxLength: undefined,
    minLength: undefined,
    options: undefined,
    defaultValue: undefined,
    isReadOnly: false,
    isHidden: false,
  },
  loading: undefined,
  loadingSubmit: undefined,
  submitted: undefined,
  trackId: undefined,
  marketplaceUrl: undefined,
  errMess: null,
}

type actionType =
  | {
      type: userFormTypes.CLEAR_FORM_FIELD_ERRORS
    }
  | {
      type: userFormTypes.GET_USER_FORM_DATA
      payload: UserForm
    }
  | {
      type: userFormTypes.GET_USER_FORM_ERROR
      payload: string
    }
  | {
      type: userFormTypes
      payload?: unknown
    }

const isReadOnlyOrHide = (
  field: FormField & FormDefaultValues,
  status?: string
) => {
  switch (status) {
    case 'draft':
      return { readOnly: field.disableInCreation, hidden: field.hideInCreation }
    case 'submitted':
      return { readOnly: field.disableInUpdate, hidden: field.hideInUpdate }

    default:
      return { readOnly: false, hidden: false }
  }
}

const addPartnerCentralUserDataOptions = (
  field: FormField,
  data: PartnerCentralUsers[]
) => {
  const options = data?.map(a => a.email) || []
  return field.id === 'Labra__AWS_Partner_Central_User_Email__c'
    ? options
    : field.options
}

const changeDefaultByFieldId = (
  fieldsFromForm: { [x: string]: unknown },
  partnerCentralUsers: PartnerCentralUsers[],
  id: string,
  defaultValue: unknown
) => {
  if (id === 'Labra__AWS_Partner_Central_User_Name__c') {
    const partnerCentralEmail =
      fieldsFromForm?.['Labra__AWS_Partner_Central_User_Email__c'] || ''
    const partnerInfo = partnerCentralUsers.find(
      p => p.email === partnerCentralEmail
    )
    return partnerInfo
      ? `${partnerInfo?.first_name} ${partnerInfo?.last_name}`
      : defaultValue
  } else {
    return fieldsFromForm[id] ? fieldsFromForm[id] : defaultValue
  }
}
export const reducer = (
  state: UserFormState = initialState,
  action: actionType
) => {
  switch (action.type) {
    case userFormTypes.GET_USER_FORM_DATA: {
      const userForm = action.payload as UserForm

      userForm.formFields = orderBy(userForm.formFields, ['displayOrder'])
      return {
        ...state,
        customData: {
          ...state.customData,
          formFields: userForm.formFields,
        },
        errMess: null,
      }
    }
    case userFormTypes.GET_USER_FORM_ERROR: {
      return { ...state, customData: null, errMess: action.payload }
    }
    case userFormTypes.USER_FORM_LOADING: {
      return { ...state, loading: action.payload }
    }
    case userFormTypes.USER_FORM_LOADING_SUBMIT: {
      return { ...state, loadingSubmit: action.payload }
    }
    case userFormTypes.USER_FORM_UPDATE_SUBMIT: {
      return { ...state, submitted: action.payload }
    }
    case userFormTypes.USER_FORM_UPDATE_TRACK_ID: {
      const { trackId, marketplaceUrl } = action.payload as {
        trackId: string
        marketplaceUrl: string
      }
      return { ...state, trackId: trackId, marketplaceUrl: marketplaceUrl }
    }
    case userFormTypes.USER_FORM_UPDATE_REFERRAL_METADATA_AND_OBJECT: {
      const payload = action.payload as {
        data: {
          labra_setting_object: LabraSettingObject
          referral_metadata: ReferralMetadata
          referral_object: Record<string, string>
          partner_central_users: PartnerCentralUsers[]
        }
        crmUserId: string
        referralActionType: ReferralActionType
        defaultReferralType: string
        secretKey?: string
        portalId: string
      }
      const formFields = state.customData?.formFields || []
      const referralObjects = payload.data.referral_object
      const partnerCentralUsers = payload.data.partner_central_users
      const status = payload.data.referral_metadata.referral_status

      formFields.map(f => {
        mergeDefaultValues(
          f as unknown as Record<string, unknown>,
          state.defaultValues as unknown as Record<string, unknown>
        )
      })
      const newFormFieldsPrefilled = formFields.map(f => {
        const { readOnly, hidden } = isReadOnlyOrHide(
          f as FormField & FormDefaultValues,
          status
        )

        return {
          ...f,
          defaultValue: changeDefaultValue(f, referralObjects),
          isReadOnly: readOnly,
          isHidden: hidden,
          options: addPartnerCentralUserDataOptions(f, partnerCentralUsers),
        }
      })
      const finalFields = changeReadOnlyBaseOnFieldAndValue(
        newFormFieldsPrefilled,
        'Labra__AWS_Acceptance_Status__c',
        'Action Required',
        [
          'Labra__Country__c',
          'Labra__State__c',
          'Labra__Postal_Code__c',
          'Labra__Website__c',
        ]
      )
      return {
        ...state,
        customData: {
          ...state.customData,
          formFields: finalFields,
          referralMetadata: {
            ...payload.data.referral_metadata,
            crm_user_id: payload.crmUserId,
          },
          labraSettingObject: payload.data.labra_setting_object,
          referralObjects: {
            ...referralObjects,
            Labra__Referred_By__c: getReferredBy(
              referralObjects.Labra__Referred_By__c
            ),
          },
          partnerCentralUsers: payload.data.partner_central_users,
          secretKey: payload.secretKey,
          portalId: payload.portalId,
          referralFormData: referralAPIToForm(referralObjects),
        },
      }
    }

    case userFormTypes.SET_FORM_FIELD_ERRORS: {
      const errors = action.payload as Record<string, string>
      return {
        ...state,
        customData: {
          ...state.customData,
          formFields: (state.customData?.formFields || []).map(field => {
            if (!isEmpty(errors[field.id])) {
              return {
                ...field,
                error: errors[field.id],
              }
            }
            return field
          }),
        },
      }
    }
    case userFormTypes.CLEAR_FORM_FIELD_ERRORS: {
      return {
        ...state,
        customData: {
          ...state.customData,
          formFields: (state.customData?.formFields || []).map(field => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { error, ...newField } = field
            return newField
          }),
        },
      }
    }
    case userFormTypes.CHANGE_DEFAULT_OPTIONS: {
      const { options, key } = action.payload as {
        options: unknown
        key: string
      }
      return {
        ...state,
        customData: {
          ...state.customData,
          formFields: (state.customData?.formFields || []).map(field =>
            field.id === key ? { ...field, options: options } : field
          ),
        },
      }
    }
    case userFormTypes.CHANGE_DEFAULT_VALUE: {
      const { values } = action.payload as {
        values: { [x: string]: unknown }
      }
      const formFields = state.customData?.formFields || []
      const partnerCentralUsers = state.customData?.partnerCentralUsers || []
      const newFieldsDefaultValues = formFields.map(f => {
        return {
          ...f,
          defaultValue: changeDefaultByFieldId(
            values,
            partnerCentralUsers,
            f.id,
            f.defaultValue
          ),
        }
      })

      return {
        ...state,
        customData: {
          ...state.customData,
          formFields: newFieldsDefaultValues,
        },
      }
    }
    case userFormTypes.GET_FIELDS_CATEGORIES: {
      const categories = action.payload as Categories

      return {
        ...state,
        customData: {
          ...state.customData,

          categoriesFields: categories,
        },
        errMess: null,
      }
    }

    default:
      return state
  }
}
