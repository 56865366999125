import {
  cloudfaasDefaultColor,
  flyoutDefaultColor,
  oppsyncDefaultColor,
} from '../../utils/constants'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import DescriptionIcon from '@material-ui/icons/Description'
import SettingsIcon from '@material-ui/icons/Settings'
import {
  IconTypeForSidePanel,
  NavigationRoutesType,
} from '@labrav/react-components/lib/@types/components/SidePanel/SidePanel'
import { AccountCircle, Dvr } from '@material-ui/icons'
import { AutoGraph, HandshakeOutlined } from '@mui/icons-material'

export const OppSyncNavigationRoutes: NavigationRoutesType[] = [
  {
    key: 1,
    path: '/oppsync/dashboard',
    name: 'Dashboard',
    icon: InsertChartIcon,
    disabled: false,
  },
  {
    key: 2,
    path: '/oppsync/sales?object_type=opportunity',
    name: 'Sales pipeline',
    icon: AssignmentTurnedInIcon,
    disabled: false,
  },
  {
    key: 3,
    path: '/oppsync/sync-history',
    name: 'Sync history',
    icon: DescriptionIcon,
    disabled: false,
  },
]


export const FlyOutNavigationRoutes: NavigationRoutesType[] = [
  {
    key: 1,
    path: '/flyout/products-listing',
    name: 'Product listings',
    icon: Dvr,
    disabled: false,
  },
  {
    key: 2,
    path: '/flyout/offers-authorizations/private-offers',
    name: 'Offers & authorizations',
    icon: HandshakeOutlined as IconTypeForSidePanel,
    disabled: false,
  },
  {
    key: 3,
    path: '/flyout/revenue',
    name: 'Revenue insights',
    icon: AutoGraph as IconTypeForSidePanel,
    disabled: false,
  },
]
export const CloudFaaSNavigationRoutes: NavigationRoutesType[] = [
  {
        key: 1,
        path: '/cloudfaas/ftr',
        name: 'FTR listings',
        icon: Dvr,
        disabled: false,
      },
  {
    key: 2,
    path: '/cloudfaas/wafr',
    name: 'WAFR',
    icon: HandshakeOutlined as IconTypeForSidePanel,
    disabled: false,
  },
]
export const oldFooterRoutes: NavigationRoutesType[] = [
  {
    key: 5,
    path: '/oppsync/settings/crm',
    name: 'Settings',
    icon: SettingsIcon,
    disabled: false,
  },
  {
    key: 6,
    path: '/user/profile',
    name: 'Profile',
    hasSubMenu: true,
    icon: AccountCircle,
    disabled: false,
  },
]
export const footerRoutes: NavigationRoutesType[] = [
  {
    key: 5,
    path: '/settings',
    name: 'Settings',
    icon: SettingsIcon,
    disabled: false,
  },
  {
    key: 6,
    path: '/user/profile',
    name: 'Profile',
    hasSubMenu: true,
    icon: AccountCircle,
    disabled: false,
  },
]

export const footerRoutesForAdmin: NavigationRoutesType[] = [
  {
    key: 6,
    path: '/user/profile',
    name: 'Profile',
    hasSubMenu: true,
    icon: AccountCircle,
    disabled: false,
  },
]

export const productSelectorData = {
  active: '',
  options: [
    {
      name: 'OS',
      label: 'Labra OppSync',
      link: 'labra.io/oppsync/',
      bgColor: oppsyncDefaultColor,
      textColor: 'white',
    },
    {
      name: 'FO',
      label: 'Labra FlyOut',
      link: 'labra.io/oppsync/',
      bgColor: flyoutDefaultColor,
      textColor: 'white',
    },
    {
      name: 'CF',
      label: 'Labra CloudFaaS',
      link: 'labra.io/cloudfaas/',
      bgColor: cloudfaasDefaultColor,
      textColor: 'white',
    },
  ],
}
