import { ReactNode, useEffect, useState } from 'react'
import { serviceEnvironment, viteSegmentApiWriteKey } from '../constants'
import { Tracker } from './Tracker'
import { TrackerContext } from './context'
import { useSelector } from '../../../store'

export const TrackerProvider = ({
  children,
  analytics,
}: {
  children: ReactNode
  analytics?: SegmentAnalytics.AnalyticsJS
}) => {
  const [tracker, setTracker] = useState(() => new Tracker(analytics))

  const isInternal = useSelector(
    state => state.PartnerData.user.partnerData?.isInternal
  )

  useEffect(() => {
    if (isInternal === true) {
      console.debug('Partner is internal, so skipping the tracker')
      setTracker(new Tracker())
      return
    }
    if (
      isInternal === false &&
      typeof window !== undefined &&
      window.segmentScriptRun
    ) {
      window.segmentScriptRun(viteSegmentApiWriteKey, serviceEnvironment)
      if (window.analytics) {
        setTracker(new Tracker(window.analytics))
      } else {
        console.error('Unable to load segment')
      }
    } else {
      console.error('Unable to load segment')
    }
  }, [analytics, isInternal])

  return (
    <TrackerContext.Provider value={tracker}>
      {children}
    </TrackerContext.Provider>
  )
}
