import { combineReducers } from 'redux'
import {
  reducer as OnboardingReducer,
  OnboardingState,
} from '../common/modules/onboarding/reducer'

import {
  reducer as loadingReducer,
  ComponentLoadingState,
} from '../common/modules/loading/reducer'

import {
  reducer as faqDrawerReducer,
  FaqDrawerState,
} from '../common/modules/faqDrawer/reducer'

import { crmSelectorReducer } from '../common/modules/onboarding/crmSelector/reducer'

import { CRMListState } from '../common/modules/onboarding/crmSelector/action'

import {
  reducer as AlertReducer,
  AppAlertState,
} from '../common/modules/appAlert/reducer'

import {
  CRMAuthReducer,
  CRMAuthState,
} from '../common/modules/onboarding/crmAuth/reducer'
import {
  reducer as chartReducer,
  DashBoardChartsState,
} from '../oppsync/modules/dashboard/reducer'

import {
  PartnerDataState,
  PartnerReducer,
} from '../common/modules/partner/reducer'
import {
  reducer as CrmAceMappingReducer,
  CrmAceMappingState,
} from '../common/modules/onboarding/CrmAceMapping/reducer'

import {
  reducer as AllPartnersReducer,
  AllPartnerState,
} from '../common/modules/allPartners/reducer'
import {
  reducer as UserListReducer,
  UserListState,
} from '../oppsync/modules/userList/reducer'
import { reducerTypeWrapper } from '../common/utils/actionTypeWrapper'
import { validateReducer } from '../common/modules/onboarding/validate/reducer'
import { Validate } from '../common/modules/onboarding/validate/action'
import {
  SyncHistoryReducer,
  SyncHistoryState,
} from '../oppsync/modules/syncHistory/reducer'

import {
  reducer as SupportFormReducer,
  SupportFormState,
} from '../common/modules/onboarding/supportForm/reducer'
import {
  reducer as PartnerStatsReducer,
  PartnerObjectsStatsState,
} from '../oppsync/modules/salesPipeline/partnerStats/reducer'

import {
  reducer as PartnerObjectsReducer,
  PartnerObjectsState,
} from '../oppsync/modules/salesPipeline/partnerObjects/reducer'
import {
  UserProfileReducer,
  UserProfileState,
} from '../common/modules/userProfile/reducer'
import {
  SubscriptionReducer,
  SubscriptionState,
} from '../oppsync/modules/subscription/reducer'
import { defaultValueValidationReducer } from '../common/modules/onboarding/defaultValueValidation/reducer'
import { DefaultValueState } from '../common/modules/onboarding/defaultValueValidation/action'
import {
  SyncReportReducer,
  SyncReportState,
} from '../admin/modules/syncReport/reducer'
import {
  reducer as SavedReducer,
  SavedStatus,
} from '../common/modules/savedStatus/reducer'
import {
  reducer as SyncObjectReducer,
  SyncObjectState,
} from '../oppsync/modules/salesPipeline/syncObject/reducer'
import {
  reducer as RuleDefinition,
  RuleDefinitionMappingState,
} from '../common/modules/onboarding/ruleDefinitions/reducer'
import {
  reducer as AddUsersReducer,
  AddNewUsersState,
  zeroValueAddUserForm,
  UserType,
} from '../common/modules/AddUserForm/reducer'
import {
  reducer as CrmTablesReducer,
  CrmTableState,
} from '../common/modules/onboarding/crmTables/reducer'
import {
  CRMSchemaState,
  CRMSchemaReducer,
} from '../common/modules/onboarding/crmSchema/reducer'
import {
  CrmFieldReducerState,
  reducer as CrmFieldReducer,
} from '../common/modules/onboarding/CrmFields/reducer'
import {
  reducer as CRMTableFieldsLoading,
  CRMTableFieldLoadingState,
} from '../common/modules/onboarding/crmFieldsLoading/reducer'
import {
  reducer as NotificationSidePanelReducer,
  NotificationPanelState,
} from '../oppsync/modules/notification/notificationSidePanel/reducer'

import {
  reducer as NotificationsReducer,
  NotificationsState,
} from '../oppsync/modules/notification/reducer'
import {
  reducer as WarningStatusReducer,
  WarningState,
} from '../common/modules/onboarding/warningStatus/reducer'

import {
  reducer as ProductsListingReducer,
  ProductsListingState,
} from '../flyout/modules/productsListing/reducer'

import {
  reducer as TemplateCreationReducer,
  TemplateInCreation,
} from '../flyout/modules/storefront/templateCreation/reducer'

import {
  reducer as AccountsReducer,
  CmcmAccountState,
} from '../common/modules/cmcm/accounts/reducer'

import {
  AccountsState,
  reducer as AccountsStateReducer,
} from '../oppsync/modules/productAccount/reducer'

import {
  reducer as PrivateOffersReducer,
  PrivateOffersState,
} from '../flyout/modules/privateOffer/reducer'
import {
  reducer as OverviewReducer,
  OverviewState,
} from '../flyout/modules/overview/reducer'
import {
  reducer as FreeTrialsReducer,
  FreeTrialsState,
} from '../flyout/modules/freeTrials/reducer'
import {
  reducer as CPPOAdminReducer,
  ResaleAuthorizationsState,
} from '../admin/modules/resaleAuthorizations/reducer'
import {
  reducer as CustomFormReducer,
  UserFormState,
} from '../externalApps/modules/customForm/reducer'
import {
  reducer as RevenueInsightReducer,
  ReportsData,
} from '../flyout/modules/revenueInsights/reducer'
import {
  reducer as RevenueInsightReducerV2,
  ReportsData as ReportsDataV2,
} from '../flyout/modules/revenueInsightsV2/reducer'
import {
  RevenueDisbursementSidePanel,
  reducer as RevenueDisbursementPanelReducer,
} from '../flyout/modules/revenueInsights/revenueInsightsDisbursementPanel/reducer'
import {
  PrivateOffersInCreation,
  reducer as PrivateOffersCreation,
} from '../flyout/modules/privateOffer/privateOfferCreation/reducer'
import {
  ResaleAuthorizationsInCreation,
  reducer as ResaleAuthorizationsCreation,
} from '../flyout/modules/resaleAuthorization/reducer'
import {
  CPPOResellerAgreementState,
  reducer as CPPOResellerAgreementReducer,
} from '../externalApps/modules/cppoResellerAgreement/reducer'
import {
  IntegrationsState,
  reducer as IntegrationsReducer,
} from '../common/modules/thirdPartyAppIntegration/reducer'
import {
  SlackChannelsDataState,
  reducer as SlackChannelsReducer,
} from '../common/modules/slackChannels/reducer'
import {
  reducer as PlatformSettingsReducer,
  PlatformSettingsState,
} from '../common/modules/platformSettings/reducer'
import {
  reducer as CRMConfigurationReducer,
  CRMConfigurationState,
  PartnerCentralDetailsWithCloudProviders,
  zeroValuePartnerCentralDetails,
  zeroValueDefaultValues,
  DefaultValues,
} from '../oppsync/modules/CRMConfiguration/reducer'
import {
  reducer as ResellerReducer,
  ResellerState,
} from '../flyout/modules/reseller/reducer'
import { arrayReducer, ArrayReducerRow } from '../common/modules/arrayReducer'
import {
  FlyOutListingData,
  reducer as FlyOutSyncs,
} from '../admin/modules/flyOutSyncs/reducer'
import {
  reducer as ProductListingFiltersReducer,
  ProductListingFilters,
} from '../flyout/modules/productListingFilter/reducer'

import {
  reducer as ContractsListingReducer,
  ContractsListingState,
} from '../flyout/modules/contractsListing/reducer'
import {
  reducer as FlyOutOnboardingReducer,
  FlyOutOnboarding,
} from '../flyout/modules/flyOutOnboarding/reducer'
import {
  reducer as FlyOutMigrationProductsInfoReducer,
  FlyOutMigrationProductsInfo,
} from '../flyout/modules/addEditProduct/productsInfo/reducer'

import {
  reducer as AutoSaveReducer,
  AutoSaveState,
} from '../common/modules/autoSave/reducer'
import {
  CloudAccountState,
  reducer as CloudAccountReducer,
} from '../common/modules/CloudAccounts/reducer'
import {
  reducer as SubscriptionsReducer,
  SubscriptionsState,
} from '../flyout/modules/subscription/reducer'
import {
  reducer as CoSellReducer,
  CoSellState,
} from '../oppsync/modules/CoSell/reducer'
import {
  reducer as ReferralFormCreationReducer,
  ReferralForm,
} from '../oppsync/modules/CoSell/ReferralForm/reducer'
import {
  reducer as ReferralLeadFormReducer,
  ReferralForm as LeadReferralForm,
} from '../oppsync/modules/CoSell/LeadsReferralForm/reducer'
import {
  ProductInCreation,
  reducer as FlyoutProdcutInCreationReducer,
} from '../flyout/modules/addEditProduct/reducer'
import {
  Customer,
  reducer as NewCustomers,
} from '../admin/modules/NewCustomers/reducer'
import {
  reducer as MarketplaceSeoReducer,
  MpsSeoState,
} from '../common/modules/mpsSeo/reducer'
import {
  reducer as ListingCardCreationReducer,
  ListingCardInCreation,
} from '../flyout/modules/storefront/listingCardCreation/reducer'
import {
  reducer as StorefrontDataReducer,
  StorefrontState,
} from '../flyout/modules/storefront/reducer'
import {
  reducer as StorefrontMetricsDataReducer,
  MetricsData,
} from '../flyout/modules/storefrontMetrics/reducer'

import {
  reducer as propensityReducer,
  PropensityData,
} from '../oppsync/modules/Propensity/reducer'
// When adding more reducers with state, just add them here with intersections
// https://stackoverflow.com/a/27326935
// RootState = CounterState & AuthState & ScrollState
export type AppState = {
  loading: ComponentLoadingState
  faqDrawer: FaqDrawerState
  onboarding: {
    user: OnboardingState
    admin: OnboardingState
  }
  crmSelector: CRMListState
  appAlert: AppAlertState
  savedStatus: SavedStatus
  CRMAuth: CRMAuthState
  chartsData: DashBoardChartsState
  PartnerData: {
    user: PartnerDataState
    admin: PartnerDataState
  }
  partnerStats: PartnerObjectsStatsState
  partnerObject: {
    leadsData: PartnerObjectsState
    opportunitiesData: PartnerObjectsState
  }
  syncObject: SyncObjectState
  AllPartners: AllPartnerState
  crmAceMapping: {
    lead: CrmAceMappingState
    opportunity: CrmAceMappingState
  }
  validationCrmAceMappingKey: {
    lead: Validate
    opportunity: Validate
  }
  defaultValueValidation: {
    lead: DefaultValueState
    opportunity: DefaultValueState
  }
  ruleDefinition: {
    lead: RuleDefinitionMappingState
    opportunity: RuleDefinitionMappingState
  }
  syncHistory: SyncHistoryState
  syncReport: SyncReportState
  showSupportForm: SupportFormState
  userProfile: UserProfileState
  subscription: SubscriptionState
  userList: {
    user: UserListState
    admin: UserListState
  }
  addNewUsers: {
    metadata: AddNewUsersState
    users: ArrayReducerRow<UserType>[]
  }
  crmTables: CrmTableState
  crmSchema: CRMSchemaState
  crmFields: CrmFieldReducerState
  crmTableFieldsLoading: CRMTableFieldLoadingState
  CRMConfiguration: {
    metadata: CRMConfigurationState
    partnerCentralDetails: PartnerCentralDetailsWithCloudProviders
    defaultValues: ArrayReducerRow<DefaultValues>[]
  }
  notificationSidePanel: NotificationPanelState
  notifications: {
    oppsync: NotificationsState
    flyout: NotificationsState
    cloudfaas: NotificationsState
  }
  warningStatus: {
    lead: WarningState
    opportunity: WarningState
  }
  productListingFilters: ProductListingFilters
  productsListing: {
    AWS: ProductsListingState
    AZURE: ProductsListingState
    GCP: ProductsListingState
    REDHAT: ProductsListingState
  }

  marketplaceSeo: {
    AWS: MpsSeoState
    AZURE: MpsSeoState
    GCP: MpsSeoState
    REDHAT: MpsSeoState
  }

  accountForm: {
    oppsync: AccountsState
    flyout: AccountsState
    cloudfaas: AccountsState
  }

  accounts: {
    oppsync: CmcmAccountState
    flyout: CmcmAccountState
    cloudfaas: CmcmAccountState
  }
  contractsListing: ContractsListingState
  privateOffers: PrivateOffersState
  flyoutOverview: OverviewState
  freeTrials: FreeTrialsState

  customForm: {
    hubspotForm: UserFormState
  }
  cppoReseller: ResaleAuthorizationsState
  revenueInsights: {
    daily: ReportsData
    monthly: ReportsData
    disbursement: ReportsData
    cloudDisbursement: ReportsData
  }
  revenueDisbursementSidePanel: RevenueDisbursementSidePanel
  revenueInsightsV2: ReportsDataV2
  templateCreation: TemplateInCreation
  propensity: PropensityData
  privateOffersInCreation: PrivateOffersInCreation
  cppoResellerAgreement: Partial<CPPOResellerAgreementState>
  integrations: IntegrationsState
  slackChannels: {
    oppsync: SlackChannelsDataState
    flyout: SlackChannelsDataState
    cloudfaas: SlackChannelsDataState
  }
  listingCardInCreation: ListingCardInCreation
  platformSettings: PlatformSettingsState
  resaleAuthorizationsInCreation: ResaleAuthorizationsInCreation
  resellers: ResellerState
  flyoutSellerInfo: FlyOutListingData
  flyOutOnboarding: FlyOutOnboarding
  flyOutMigrationProductsInfo: FlyOutMigrationProductsInfo
  autoSave: AutoSaveState

  cloudAccounts: {
    oppsync: CloudAccountState
    flyout: CloudAccountState
    cloudfaas: CloudAccountState
  }
  subscriptions: SubscriptionsState

  CoSell: {
    oppsync: CoSellState
    flyout: CoSellState
    cloudfaas: CoSellState
  }
  referralFormCreation: ReferralForm
  leadReferralForm: LeadReferralForm
  flyoutProductInCreation: ProductInCreation
  newCustomerCreation: Customer
  storefrontData: StorefrontState
  storefrontMetricsData: MetricsData
}

export const rootReducer = combineReducers({
  loading: loadingReducer,
  faqDrawer: faqDrawerReducer,
  onboarding: combineReducers<{
    user: OnboardingState
    admin: OnboardingState
  }>({
    user: reducerTypeWrapper('user', OnboardingReducer),
    admin: reducerTypeWrapper('admin', OnboardingReducer),
  }),
  crmSelector: crmSelectorReducer,
  appAlert: AlertReducer,
  savedStatus: SavedReducer,
  CRMAuth: CRMAuthReducer,

  PartnerData: combineReducers<{
    user: PartnerDataState
    admin: PartnerDataState
  }>({
    user: reducerTypeWrapper('user', PartnerReducer),
    admin: reducerTypeWrapper('admin', PartnerReducer),
  }),
  chartsData: chartReducer,
  partnerStats: PartnerStatsReducer,
  partnerObject: combineReducers<{
    leadsData: PartnerObjectsState
    opportunitiesData: PartnerObjectsState
  }>({
    leadsData: reducerTypeWrapper('lead', PartnerObjectsReducer),
    opportunitiesData: reducerTypeWrapper('opportunity', PartnerObjectsReducer),
  }),
  syncObject: SyncObjectReducer,
  crmAceMapping: combineReducers<{
    lead: CrmAceMappingState
    opportunity: CrmAceMappingState
  }>({
    lead: reducerTypeWrapper('lead', CrmAceMappingReducer),
    opportunity: reducerTypeWrapper('opportunity', CrmAceMappingReducer),
  }),
  validationCrmAceMappingKey: combineReducers<{
    lead: Validate
    opportunity: Validate
  }>({
    lead: reducerTypeWrapper('lead', validateReducer),
    opportunity: reducerTypeWrapper('opportunity', validateReducer),
  }),
  defaultValueValidation: combineReducers<{
    lead: DefaultValueState
    opportunity: DefaultValueState
  }>({
    lead: reducerTypeWrapper('lead', defaultValueValidationReducer),
    opportunity: reducerTypeWrapper(
      'opportunity',
      defaultValueValidationReducer
    ),
  }),
  ruleDefinition: combineReducers<{
    lead: RuleDefinitionMappingState
    opportunity: RuleDefinitionMappingState
  }>({
    lead: reducerTypeWrapper('lead', RuleDefinition),
    opportunity: reducerTypeWrapper('opportunity', RuleDefinition),
  }),
  syncHistory: SyncHistoryReducer,
  showSupportForm: SupportFormReducer,
  AllPartners: AllPartnersReducer,
  userProfile: UserProfileReducer,
  subscription: SubscriptionReducer,
  syncReport: SyncReportReducer,
  userList: combineReducers<{
    user: UserListState
    admin: UserListState
  }>({
    user: reducerTypeWrapper('user', UserListReducer),
    admin: reducerTypeWrapper('admin', UserListReducer),
  }),
  addNewUsers: combineReducers({
    users: reducerTypeWrapper(
      'addNewUsers',
      arrayReducer(zeroValueAddUserForm)
    ),
    metadata: AddUsersReducer,
  }),
  crmTables: CrmTablesReducer,
  crmSchema: CRMSchemaReducer,
  crmFields: CrmFieldReducer,
  crmTableFieldsLoading: CRMTableFieldsLoading,
  CRMConfiguration: combineReducers({
    partnerCentralDetails: combineReducers({
      AWS: reducerTypeWrapper(
        'awsPartnerCentralUsersDetails',
        arrayReducer(zeroValuePartnerCentralDetails)
      ),
      AZURE: reducerTypeWrapper(
        'azurePartnerCentralUsersDetails',
        arrayReducer(zeroValuePartnerCentralDetails)
      ),
      GCP: reducerTypeWrapper(
        'gcpPartnerCentralUsersDetails',
        arrayReducer(zeroValuePartnerCentralDetails)
      ),
      REDHAT: reducerTypeWrapper(
        'redhatPartnerCentralUsersDetails',
        arrayReducer(zeroValuePartnerCentralDetails)
      ),
    }),
    metadata: CRMConfigurationReducer,
    defaultValues: reducerTypeWrapper(
      'defaultValues',
      arrayReducer(zeroValueDefaultValues)
    ),
  }),
  notificationSidePanel: NotificationSidePanelReducer,
  notifications: combineReducers({
    oppsync: reducerTypeWrapper('oppsync', NotificationsReducer),
    flyout: reducerTypeWrapper('flyout', NotificationsReducer),
    cloudfaas: reducerTypeWrapper('cloudfaas', NotificationsReducer),
  }),
  warningStatus: combineReducers<{
    lead: WarningState
    opportunity: WarningState
  }>({
    lead: reducerTypeWrapper('lead', WarningStatusReducer),
    opportunity: reducerTypeWrapper('opportunity', WarningStatusReducer),
  }),
  productsListing: combineReducers<{
    AWS: ProductsListingState
    AZURE: ProductsListingState
    GCP: ProductsListingState
    REDHAT: ProductsListingState
  }>({
    AWS: reducerTypeWrapper('AWS', ProductsListingReducer),
    AZURE: reducerTypeWrapper('AZURE', ProductsListingReducer),
    GCP: reducerTypeWrapper('GCP', ProductsListingReducer),
    REDHAT: reducerTypeWrapper('REDHAT', ProductsListingReducer),
  }),
  accountForm: combineReducers<{
    oppsync: AccountsState
    flyout: AccountsState
    cloudfaas: AccountsState
  }>({
    oppsync: reducerTypeWrapper('oppsync', AccountsStateReducer),
    flyout: reducerTypeWrapper('flyout', AccountsStateReducer),
    cloudfaas: reducerTypeWrapper('cloudfaas', AccountsReducer),
  }),
  accounts: combineReducers<{
    oppsync: CmcmAccountState
    flyout: CmcmAccountState
    cloudfaas: CmcmAccountState
  }>({
    oppsync: reducerTypeWrapper('oppsync', AccountsReducer),
    flyout: reducerTypeWrapper('flyout', AccountsReducer),
    cloudfaas: reducerTypeWrapper('cloudfaas', AccountsReducer),
  }),

  contractsListing: ContractsListingReducer,
  privateOffers: PrivateOffersReducer,
  flyoutOverview: OverviewReducer,
  freeTrials: FreeTrialsReducer,
  customForm: combineReducers<{
    hubspotForm: UserFormState
  }>({
    hubspotForm: reducerTypeWrapper('hubspotForm', CustomFormReducer),
  }),
  cppoReseller: CPPOAdminReducer,
  revenueInsights: combineReducers<{
    daily: ReportsData
    monthly: ReportsData
    disbursement: ReportsData
    cloudDisbursement: ReportsData
  }>({
    daily: reducerTypeWrapper('daily', RevenueInsightReducer),
    monthly: reducerTypeWrapper('monthly', RevenueInsightReducer),
    cloudDisbursement: reducerTypeWrapper(
      'cloudDisbursement',
      RevenueInsightReducer
    ),
    disbursement: reducerTypeWrapper('disbursement', RevenueInsightReducer),
  }),
  revenueDisbursementSidePanel: RevenueDisbursementPanelReducer,
  revenueInsightsV2: RevenueInsightReducerV2,
  privateOffersInCreation: PrivateOffersCreation,
  cppoResellerAgreement: CPPOResellerAgreementReducer,
  integrations: IntegrationsReducer,
  slackChannels: combineReducers({
    oppsync: reducerTypeWrapper('oppsync', SlackChannelsReducer),
    flyout: reducerTypeWrapper('flyout', SlackChannelsReducer),
    cloudfaas: reducerTypeWrapper('cloudfaas', SlackChannelsReducer),
  }),
  platformSettings: PlatformSettingsReducer,
  resaleAuthorizationsInCreation: ResaleAuthorizationsCreation,
  resellers: ResellerReducer,
  flyoutSellerInfo: FlyOutSyncs,
  productListingFilters: ProductListingFiltersReducer,
  flyOutOnboarding: FlyOutOnboardingReducer,
  flyOutMigrationProductsInfo: FlyOutMigrationProductsInfoReducer,
  autoSave: AutoSaveReducer,
  cloudAccounts: combineReducers<{
    oppsync: CloudAccountState
    flyout: CloudAccountState
    cloudfaas: CloudAccountState
  }>({
    oppsync: reducerTypeWrapper('oppsync', CloudAccountReducer),
    flyout: reducerTypeWrapper('flyout', CloudAccountReducer),
    cloudfaas: reducerTypeWrapper('cloudfaas', CloudAccountReducer),
  }),
  subscriptions: SubscriptionsReducer,
  cosell: combineReducers<{
    oppsync: CoSellState
    flyout: CoSellState
    cloudfaas: CoSellState
  }>({
    oppsync: reducerTypeWrapper('oppsync', CoSellReducer),
    flyout: reducerTypeWrapper('flyout', CoSellReducer),
    cloudfaas: reducerTypeWrapper('cloudfaas', CoSellReducer),
  }),
  referralFormCreation: ReferralFormCreationReducer,
  leadReferralForm: ReferralLeadFormReducer,
  flyoutProductInCreation: FlyoutProdcutInCreationReducer,
  newCustomerCreation: NewCustomers,
  marketplaceSeo: combineReducers<{
    AWS: MpsSeoState
    AZURE: MpsSeoState
    GCP: MpsSeoState
    REDHAT: MpsSeoState
  }>({
    AWS: reducerTypeWrapper('AWS', MarketplaceSeoReducer),
    AZURE: reducerTypeWrapper('AZURE', MarketplaceSeoReducer),
    GCP: reducerTypeWrapper('GCP', MarketplaceSeoReducer),
    REDHAT: reducerTypeWrapper('REDHAT', MarketplaceSeoReducer),
  }),
  templateCreation: TemplateCreationReducer,
  listingCardInCreation: ListingCardCreationReducer,
  storefrontData: StorefrontDataReducer,
  propensity: propensityReducer,
  storefrontMetricsData: StorefrontMetricsDataReducer,
})
