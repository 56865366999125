import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  clearDraftProductCreationFail,
  clearDraftProductCreationSuccess,
  draftProductCreationFail,
  draftProductCreationSuccess,
  productCloneSuccess,
  productCreationSuccess,
  RequestFailureMessage,
} from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize, snakeize } from 'casing'
import { getErrorMessages } from '../../../common/utils/error'
import { errorLogger } from '../../../common/utils/errorLogger'
import { AppDispatch, RootState } from '../../../store'
import initialProduct from '../../../mocks/initialProduct.json'
import { get, isEmpty, isEqual, isNil } from 'lodash'
import { CloudType } from '../../../common/modules/types'
import {
  BuyerFormFieldsBackend,
  FlyoutProductAddEditSectionName,
  FlyoutProductSections,
  pageMarkerType,
  ContractDimension,
  FlyoutProductBuyerFormFields,
  ProductObjectDetail,
  NotificationRecipients,
  FlyoutListingProductType,
} from './reducer'
import { FlyOutAWSProductListingType } from '../productsListing/reducer'
import { FlyOutListingUserType } from '../../../admin/modules/flyOutSyncs/reducer'
import { convertRGBAAlpha } from '../../../common/utils/convertRGBAAlpha'
import { DateTime } from 'luxon'
import {
  putFlyOutProductFormData,
  getFlyOutProductFormData,
  getOtherProductDetails,
  patchFlyOutProductFormData,
  patchFlyOutProductSync,
} from '../../api/markeplace'
import { PlanType } from '@labrav/react-components'
import flyOutProductAgreementTerms from '../../../mocks/flyOutProductAgreementTerms.json'
import { getProductPageMarkerMapping } from '../../../common/utils/getPageMarkerMapping'
import {
  allFieldsArrayNotifications,
  autoSaveThresoldTime,
} from '../../../common/utils/constants'
import { v4 as uuidv4 } from 'uuid'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { clearProductsListing, getProducts } from '../productsListing/actions'
import { convertToKebabCase } from '../../../common/utils/convertToKebabCase'
import { isNonEmptyNumber } from '../../../common/utils/validateMinMax'
import { FlyoutOnboardingSectionName } from '../flyOutOnboarding/reducer'
import { EulaType } from '../privateOffer/reducer'

export enum FlyoutProductForm {
  SET_FLYOUT_PRODUCT_FORM = 'SET_FLYOUT_PRODUCT_FORM',
  SET_FLYOUT_PRODUCT_DETAIL_FORM = 'SET_FLYOUT_PRODUCT_DETAIL_FORM',
  UPDATE_FLYOUT_PRODUCT_FORM = 'UPDATE_FLYOUT_PRODUCT_FORM',
  SET_FLYOUT_PRODUCT_LISTING_TYPE = 'SET_FLYOUT_PRODUCT_LISTING_TYPE',
  SET_ERROR_ON_FINAL_SUBMIT = 'SET_ERROR_ON_FINAL_SUBMIT',
  SET_CREATION_OTHER_INFO = 'SET_CREATION_OTHER_INFO',
  CLEAR_FLYOUT_PRODUCT_DETAIL_FORM = 'CLEAR_FLYOUT_PRODUCT_DETAIL_FORM',
  SET_DEFAULT_PRODUCT_INFO = 'SET_DEFAULT_PRODUCT_INFO',
}

const initialProductJson = { ...camelize(initialProduct) }

export const prePopulatedFieldLandingPage: Record<string, unknown> = {
  headingMessage: 'Welcome! Fill the information below to set up your account!',
  submissionSuccessMessage:
    'Thank you for completing the form. You will now be redirected to signup page.',
  emailCustomMessage:
    'Thank you for subscribing, we hope you enjoy using our product. If you have any questions, feel free to contact our customer support team!',
  existingBuyerMessage:
    'You are already a registered user in our system. Redirecting you to our login page.',
  existingBuyerRedirectSeconds: 5,
  redirectSeconds: 5,
}

export const PRODUCT_DIMENSIONS_ZERO_VALUE = {
  name: '',
  label: '',
  price: null,
  price1m: null,
  price12m: null,
  price24m: null,
  price36m: null,
  additionalUsagePrice: null,
  subscriptionPrice: null,
  labelDescription: '',
  isAdditionalUsageAllowed: false,
  additionalUsageDescription: '',
}

const getInitialProductBasedOnCloud = (
  cloudType: CloudType,
  awsProductType?: FlyOutAWSProductListingType,
  listingType?: FlyoutListingProductType,
  initialProductLogo?: string
) => {
  switch (cloudType) {
    case 'AWS': {
      return {
        ...initialProductJson,
        productDetails: {
          ...initialProductJson.productDetails,
          ...(awsProductType === FlyOutAWSProductListingType.SAAS
            ? { productType: PlanType.SAAS_CONTRACT }
            : {}),
          ...(listingType === FlyoutListingProductType.MIGRATED
            ? { productType: PlanType.SAAS_CONTRACT }
            : {}),
          listingType: listingType,
        },
        generalInformation: {
          ...initialProductJson.generalInformation,
          productLogoUrl: initialProductLogo,
        },
      }
    }
    case 'AZURE': {
      return {}
    }
    case 'GCP': {
      return {}
    }
    case 'REDHAT': {
      return {}
    }
  }
}

type GetProductInCreationArgs = {
  partnerId: string
  cloudType: CloudType
  productId?: string
  awsProductType?: FlyOutAWSProductListingType
  listingType: FlyoutListingProductType
  isDefault?: boolean
  isFreeTrialProductEnable?: boolean
}

type GetProductInCloneArgs = {
  partnerId: string
  cloudType: CloudType
  productId?: string
  awsProductType?: FlyOutAWSProductListingType
  listingType: FlyOutListingUserType
  callBackFlyoutProduct?: (productId: string) => void
  isDraftListing?: boolean
  isFreeTrialProductEnable?: boolean
}

export const getProductInCreation =
  ({
    partnerId,
    cloudType,
    productId,
    awsProductType,
    listingType,
    isFreeTrialProductEnable = false,
  }: GetProductInCreationArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    try {
      if (isEmpty(productId)) {
        const productDefaultInfo =
          getState().flyoutProductInCreation?.productDefaultInfo
        const initialProductLogo =
          getState().flyOutOnboarding?.onboardingForm[
            FlyoutOnboardingSectionName?.GENERAL_INFORMATION
          ]?.logoUrl || ''
        const initialProduct = getInitialProductBasedOnCloud(
          cloudType,
          awsProductType,
          listingType,
          initialProductLogo
        )
        const updatedInitialValue = { ...initialProduct }
        updatedInitialValue.productDimensions = {
          ...updatedInitialValue.productDimensions,
          ...(isFreeTrialProductEnable
            ? { freeTrial: { isEnable: false } }
            : {}),
        }
        await dispatch(setProductFormListingType(listingType))
        await dispatch(setOtherProductInfo({ error: '', updatedAt: undefined }))
        await dispatch(
          setProductFormData({
            ...initialProduct,
            ...(productDefaultInfo ? productDefaultInfo : {}),
          })
        )
      } else {
        const response = await getFlyOutProductFormData({
          partnerId,
          productId,
        })
        if (response) {
          const camelizedProductData = JSON.parse(
            JSON.stringify(get(camelize(response), 'data') || {}).replaceAll(
              '\\\\"',
              '"'
            )
          )
          const transformedProduct = await transformGetProductData({
            flyoutProductData: camelizedProductData.detailsJson,
            lockMetadata: camelizedProductData.lockMetadata,
          })
          const user = getState().userProfile
          const pageMarkerMapping: Record<string, string> =
            getProductPageMarkerMapping(false)
          const timeDiff = Math.ceil(
            DateTime.now().diff(
              transformedProduct?.lockMetadata?.lastActivityAt
                ? DateTime.fromISO(
                    transformedProduct?.lockMetadata?.lastActivityAt
                  )
                : DateTime.now(),
              ['seconds']
            ).seconds
          )
          if (
            timeDiff > autoSaveThresoldTime ||
            isEmpty(transformedProduct?.lockMetadata?.lastActivityAt)
          ) {
            const newWindowId = uuidv4()
            window.name = newWindowId
            transformedProduct.lockMetadata = {
              ...transformedProduct.lockMetadata,
              windowId: newWindowId,
              lockedBy: user.userProfile.id,
              lockedByName: `${user.userProfile.firstName} ${user.userProfile.lastName}`,
              lockByEmail: user.userProfile.email,
              lockAcquisitionProbe: true,
            }
            await dispatch(
              sendFlyoutProduct({
                partnerId,
                isAutoSave: true,
                productForm: transformedProduct,
                pageMarkerMapping,
                page: FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS,
                productId,
                status: 'lockAcquire',
              })
            )
          } else {
            await dispatch(
              setOtherProductInfo({
                error: camelizedProductData?.error,
                updatedAt: camelizedProductData?.updatedAt,
              })
            )
            await dispatch(setProductFormData(transformedProduct))
          }
        }
      }
    } catch (error) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    }
  }

type GetProductIDForOtherProductArgs = {
  partnerId: string
  listingLink: string
}

export const getProductIDForOtherProduct = async ({
  partnerId,
  listingLink,
}: GetProductIDForOtherProductArgs): Promise<string | undefined> => {
  try {
    const response = await getOtherProductDetails({
      partnerId,
      listingLink,
    })

    if (response) {
      const productId = response.data.id || ''
      return productId
    } else {
      console.warn('No product details found')
      return undefined
    }
  } catch (error) {
    console.error('Error fetching product ID:', error)
    return undefined
  }
}

interface GetProductDetailsArgs {
  partnerId: string
  productId?: string
  isDefault?: boolean
}

export const getProductDetail =
  ({ partnerId, productId, isDefault }: GetProductDetailsArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!isDefault) {
      await dispatch(clearProductDetail())
    }
    await dispatch(startLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    try {
      const response = await getFlyOutProductFormData({
        partnerId,
        productId,
        editPage: true,
      })
      if (response) {
        const responseData = get(response, 'data')
        const camelizedProductDetailData = JSON.parse(
          JSON.stringify(camelize(responseData)).replaceAll('\\\\"', '"')
        )
        if (!isEmpty(camelizedProductDetailData)) {
          const productDetail = {
            generalInformation: {
              ...camelizedProductDetailData?.generalInformation,
            },
            notificationRecipients: {
              ...camelizedProductDetailData?.notificationRecipients,
            },
            infoCollectionFormMetaData: {
              ...camelizedProductDetailData?.infoCollectionFormMetaData,
            },
            productDimensions: {
              ...camelizedProductDetailData?.productDimensions,
            },
            buyerFormFields: {
              ...camelizedProductDetailData?.buyerFormFields,
            },
            productDetails: {
              productType: camelizedProductDetailData?.productType,
              productName: camelizedProductDetailData?.productName,
            },
          }
          const transformedProductDetail = await transformGetProductData({
            flyoutProductData: productDetail,
            isEdit: true,
          })
          const updatedProduct = getUpdatedEmptyData(transformedProductDetail)
          const status = camelizedProductDetailData?.status
          const auditJson = responseData?.audit_json || {}
          const productName = camelizedProductDetailData?.productName
          if (!isDefault) {
            await dispatch(
              setProductDetailFormData({
                productForm: updatedProduct,
                status: status,
                auditJson: auditJson,
                productName: productName,
                marketplaceUrl: camelizedProductDetailData?.marketplaceUrl,
                edit: camelizedProductDetailData?.edit,
                awsChangeSetStatus: responseData?.aws_change_set_status,
                error: responseData?.error,
                productType: responseData?.product_type,
                updatedAt: response?.data?.updated_at,
              })
            )
          } else {
            await dispatch(
              setProductDefaultInfo({
                [FlyoutProductAddEditSectionName.LANDING_PAGE_DETAILS]:
                  updatedProduct?.infoCollectionFormMetaData,
                [FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS]:
                  updatedProduct?.notificationRecipients,
              })
            )
          }
        }
      }
    } catch (error) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    }
  }

const getUpdatedEmptyData = (transformedProductDetail: any) => {
  const {
    highlights,
    resourceUrls,
    excludedCountries,
    includedCountries,
    countriesSelected,
  } = transformedProductDetail?.generalInformation
  const notificationRecipients =
    transformedProductDetail?.notificationRecipients
  let updatedNotificationRecipients = {}
  allFieldsArrayNotifications.forEach(keyName => {
    updatedNotificationRecipients = {
      ...updatedNotificationRecipients,
      [keyName]:
        notificationRecipients[
          keyName as string as keyof NotificationRecipients
        ] || [],
    }
  })
  return {
    ...transformedProductDetail,
    generalInformation: {
      ...transformedProductDetail?.generalInformation,
      highlights: highlights || [],
      resourceUrls: resourceUrls || [],
      excludedCountries: excludedCountries || [],
      includedCountries: includedCountries || [],
      countriesSelected: countriesSelected || [],
    },
    notificationRecipients: {
      ...updatedNotificationRecipients,
    },
  }
}

export const getProductCloneDetail =
  ({
    partnerId,
    productId,
    callBackFlyoutProduct,
    isDraftListing,
    isFreeTrialProductEnable,
  }: GetProductInCloneArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    try {
      const response = await getFlyOutProductFormData({
        partnerId,
        productId,
        ...(isDraftListing ? {} : { editPage: true }),
      })
      if (!isDraftListing) {
        if (response) {
          const responseData = get(response, 'data')
          const camelizedProductDetailData = JSON.parse(
            JSON.stringify(camelize(responseData)).replaceAll('\\\\"', '"')
          )
          if (!isEmpty(camelizedProductDetailData)) {
            const productDetail = {
              generalInformation: {
                ...camelizedProductDetailData?.generalInformation,
              },
              notificationRecipients: {
                ...camelizedProductDetailData?.notificationRecipients,
              },
              infoCollectionFormMetaData: {
                ...camelizedProductDetailData?.infoCollectionFormMetaData,
              },

              productDimensions: {
                ...camelizedProductDetailData?.productDimensions,
                dimensions:
                  camelizedProductDetailData?.productDimensions?.dimensions?.map(
                    ({ id, ...rest }: any) => ({ ...rest })
                  ),
                ...(isFreeTrialProductEnable
                  ? { freeTrial: { isEnable: false } }
                  : {}),
              },
              buyerFormFields: {
                ...camelizedProductDetailData?.buyerFormFields,
              },
              productDetails: {
                productType: camelizedProductDetailData?.productType,
                productName: camelizedProductDetailData?.productName,
                listingType: 'NEW',
              },
              migrationDetails: {
                products: [],
              },
              completed: {
                value: false,
              },
              page_marker: {
                page1: false,
                page2: false,
                page3: false,
                page4: false,
              },
              terms_and_conditions: {
                product_dimensions: [
                  {
                    type: 'checkbox',
                    label:
                      'All pricing dimensions I have added relate to the actual software and do not include any other product or services unrelated to the software.',
                    value: false,
                  },
                  {
                    type: 'checkbox',
                    label:
                      'All pricing dimensions which I have mentioned are the same as the plans mentioned in my website.',
                    value: false,
                  },
                  {
                    type: 'checkbox',
                    label:
                      'My SaaS product is being billed entirely through the above mentioned dimensions on AWS Marketplace. I am not charging for any plan that does not exist in my AWS SaaS listing.',
                    value: false,
                  },
                  {
                    type: 'checkbox',
                    label:
                      'I am not collecting buyer payment information within my website or SaaS product for AWS buyers at any time, including credit card and bank account information.',
                    value: false,
                  },
                  {
                    type: 'checkbox',
                    label:
                      'Buyers will be able to see the status of their subscription within the SaaS application, including any relevant contract or subscription usage information.',
                    value: false,
                  },
                ],
                general_information: [
                  {
                    type: 'checkbox',
                    label:
                      'I have outlined the architectural boundaries between other AWS Regions and the AWS GovCloud (US) Regions, use cases for the product, and the workloads not recommended for the product.',
                    value: false,
                  },
                  {
                    type: 'checkbox',
                    label:
                      'Buyers will be able to easily get help with issues such as: using the application, troubleshooting, and requesting refunds (if applicable). Support contact options have been specified on the landing page.',
                    value: false,
                  },
                  {
                    type: 'checkbox',
                    label:
                      "Product software and metadata does not contain language that redirects users to other cloud platforms, additional products, upsell services, or free trial offers that aren't available on AWS Marketplace.",
                    value: false,
                  },
                  {
                    type: 'radio',
                    label:
                      'My product is an add-on to another product or another ISV’s product.',
                    value: false,
                    sub_terms_when_true: {
                      type: 'checkbox',
                      label:
                        'My product description indicates that it extends the functionality of the other product and that without it, the product has very limited utility. For example, This product extends the functionality of <product name> and without it, this product has very limited utility. Please note that <product name> might require its own license for full functionality with this listing.',
                      value: false,
                    },
                  },
                ],
                info_collection_form_meta_data: [
                  {
                    type: 'checkbox',
                    label:
                      "After purchasing this product from AWS Marketplace, buyers can immediately sign up to open an account and access this product's features. If immediate access is not possible, we'll send specific instructions on when and how access will be granted. Once an account is created, we'll send an email to the buyer with clear next steps.",
                    value: false,
                  },
                  {
                    type: 'checkbox',
                    label:
                      'If a buyer already has an account in my SaaS application, they will have the ability to log in.',
                    value: false,
                  },
                ],
              },
              agreement: {
                terms: [
                  {
                    label:
                      'A portion of my application is being hosted in an AWS account that I own.',
                    value: false,
                    type: 'checkbox',
                  },
                  {
                    label:
                      'All application components are being hosted in the infrastructure that I manage.',
                    value: false,
                    type: 'radio',
                    options: [
                      {
                        label: 'Yes',
                        value: false,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ],
                    sub_terms_when_false: {
                      label:
                        'My application requires additional resources in the buyer’s infrastructure and I adhere to the following:',
                      value: false,
                      points: [
                        'My application provisions resources in a secure way, such as using the AWS Security Token Service (AWS STS) or AWS Identity and Access Management (IAM).',
                        'I am providing additional documentation including a description of all provisioned AWS services, IAM policy statements, and how an IAM role or user is deployed and used in the buyer’s account.',
                        "A notification is present in the product description that explains that if the buyer incurs additional AWS infrastructure charges separate from their AWS Marketplace transaction, they're responsible for paying the additional infrastructure charges.",
                        'I am okay with an additional review by AWS Marketplace, which can take 2-4 weeks.',
                      ],
                    },
                  },
                  {
                    label: 'My product deploys an agent.',
                    value: true,
                    type: 'radio',
                    options: [
                      {
                        label: 'Yes',
                        value: false,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ],
                    sub_terms_when_true: {
                      label:
                        'I am providing instructions to the buyer that describe how to deploy the agent in their AWS account.',
                      value: false,
                    },
                  },
                ],
              },
            }
            productDetail.generalInformation.title = `(Clone) ${productDetail?.generalInformation?.title}`
            productDetail.productDetails.productName = `(Clone) ${productDetail.productDetails.productName}`
            const transformedProductDetail = await transformGetProductData({
              flyoutProductData: productDetail,
              isEdit: true,
            })
            const updatedProduct = getUpdatedEmptyData(transformedProductDetail)
            const user = getState().userProfile

            const pageMarkerMapping: Record<string, string> =
              getProductPageMarkerMapping(false)

            const newWindowId = uuidv4()
            window.name = newWindowId
            updatedProduct.lockMetadata = {
              ...updatedProduct.lockMetadata,
              windowId: newWindowId,
              lockedBy: user.userProfile.id,
              lockedByName: `${user.userProfile.firstName} ${user.userProfile.lastName}`,
              lockByEmail: user.userProfile.email,
              lockAcquisitionProbe: true,
            }
            await dispatch(
              sendFlyoutProduct({
                partnerId,
                isAutoSave: true,
                productForm: updatedProduct,
                pageMarkerMapping,
                page: FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS,
                status: 'lockAcquire',
                cloneproduct: true,
                callBackFlyoutProduct,
              })
            )
          }
        }
      } else {
        if (response) {
          const camelizedProductData = JSON.parse(
            JSON.stringify(get(camelize(response), 'data') || {}).replaceAll(
              '\\\\"',
              '"'
            )
          )
          const transformedProduct = await transformGetProductData({
            flyoutProductData: camelizedProductData.detailsJson,
            lockMetadata: camelizedProductData.lockMetadata,
          })
          transformedProduct.generalInformation.title = `(Clone) ${transformedProduct?.generalInformation?.title}`
          transformedProduct.productDetails.productName = `(Clone) ${transformedProduct.productDetails.productName}`
          transformedProduct.productDetails.productDimensions = {
            ...transformedProduct.productDetails.productDimensions,
            ...(isFreeTrialProductEnable
              ? { freeTrial: { isEnable: false } }
              : {}),
          }
          const user = getState().userProfile
          const pageMarkerMapping: Record<string, string> =
            getProductPageMarkerMapping(false)
          const newWindowId = uuidv4()
          window.name = newWindowId
          transformedProduct.lockMetadata = {
            ...transformedProduct.lockMetadata,
            windowId: newWindowId,
            lockedBy: user.userProfile.id,
            lockedByName: `${user.userProfile.firstName} ${user.userProfile.lastName}`,
            lockByEmail: user.userProfile.email,
            lockAcquisitionProbe: true,
          }
          await dispatch(
            sendFlyoutProduct({
              partnerId,
              isAutoSave: true,
              productForm: transformedProduct,
              pageMarkerMapping,
              page: FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS,
              status: 'lockAcquire',
              cloneproduct: true,
              callBackFlyoutProduct,
            })
          )
        }
      }
    } catch (error) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    }
  }

export const setProductFormData = (
  productFormData: Record<string, unknown>
) => ({
  type: FlyoutProductForm.SET_FLYOUT_PRODUCT_FORM,
  payload: productFormData,
})

export const setProductDefaultInfo = (
  productDefaultInfo: Record<string, unknown>
) => ({
  type: FlyoutProductForm.SET_DEFAULT_PRODUCT_INFO,
  payload: productDefaultInfo,
})

export const setProductDetailFormData = (
  productDetailFormData: ProductObjectDetail
) => ({
  type: FlyoutProductForm.SET_FLYOUT_PRODUCT_DETAIL_FORM,
  payload: productDetailFormData,
})
export type FlyOutProductFormChangeType = {
  sectionName: FlyoutProductAddEditSectionName
  keyName: string
  value: string | boolean
}
export const updateProductFormData = (data: FlyOutProductFormChangeType) => ({
  type: FlyoutProductForm.UPDATE_FLYOUT_PRODUCT_FORM,
  payload: data,
})
export const setProductFormListingType = (type: FlyoutListingProductType) => ({
  type: FlyoutProductForm.SET_FLYOUT_PRODUCT_LISTING_TYPE,
  payload: type,
})
export const setErrorOnFinalSubmit = (data: string) => ({
  type: FlyoutProductForm.SET_ERROR_ON_FINAL_SUBMIT,
  payload: data,
})

export const setOtherProductInfo = (
  productOtherInfo: Record<string, unknown>
) => ({
  type: FlyoutProductForm.SET_CREATION_OTHER_INFO,
  payload: productOtherInfo,
})

interface SendFlyoutProductProps {
  isAutoSave: boolean
  productForm: FlyoutProductSections
  pageMarkerMapping: Record<string, string>
  page: FlyoutProductAddEditSectionName
  partnerId: string
  productId?: string
  status?: string
  callBackFlyoutProduct?: (productId: string) => void
  isClear?: boolean
  redirectToListing?: () => void
  cloneproduct?: boolean
}

const returnTrimedValue = (value?: string) => {
  if (!value) return ''
  return value?.trim().replace(/[\r\n]+/gm, ' ')
}

const objectValueTrim = (
  objectTobeTrimed: Record<string, string | undefined>,
  specificKeys?: string[]
) => {
  return (specificKeys || Object.keys(objectTobeTrimed)).reduce<
    Record<string, string | undefined>
  >((acc, curr) => {
    acc[curr] =
      typeof objectTobeTrimed[curr] === 'string'
        ? returnTrimedValue(objectTobeTrimed[curr])
        : objectTobeTrimed[curr]
    return acc
  }, {})
}

const arrayFieldValueTrim = (
  arrayToBeTrimed: Record<string, any | undefined>[],
  fieldKeys: string[]
) => {
  return arrayToBeTrimed.map(arrayItem => ({
    ...arrayItem,
    ...objectValueTrim(
      {
        ...arrayItem,
      },
      fieldKeys
    ),
  }))
}

const stringArrayValueTrim = (arrayToBeTrimed: string[]) => {
  return arrayToBeTrimed?.map(item => returnTrimedValue(item))
}

const getTrimedValueProductForm = (productForm: FlyoutProductSections) => {
  const {
    generalInformation,
    infoCollectionFormMetaData,
    productDetails,
    productDimensions,
  } = productForm
  const {
    sku,
    shortProductDescription,
    productDescription,
    supportResources,
    refundPolicy,
    title,
    companyName,
    resourceUrls,
    highlights,
    productVideoLink,
    saasUrl,
  } = generalInformation
  const {
    redirectUrl,
    submissionSuccessMessage,
    headingMessage,
    privacyPolicyLink,
    emailCustomMessage,
    existingBuyerMessage,
    existingBuyerRedirectUrl,
    buyerFormFields,
    redirectionSettings,
  } = infoCollectionFormMetaData
  const { productName } = productDetails
  const { dimensions, additionalDimensions } = productDimensions
  const {
    applicationLoginPageUrl,
    customInstructions,
    demoDocumentUrl,
    demoVideoUrl,
    testingCredentials,
  } = redirectionSettings
  return {
    ...productForm,
    generalInformation: {
      ...generalInformation,
      ...objectValueTrim({
        sku,
        shortProductDescription,
        productDescription,
        supportResources,
        refundPolicy,
        title,
        companyName,
        productVideoLink,
        saasUrl,
      }),
      resourceUrls: arrayFieldValueTrim(resourceUrls, ['Text', 'Url']),
      highlights: stringArrayValueTrim(highlights),
    },
    infoCollectionFormMetaData: {
      ...infoCollectionFormMetaData,
      ...objectValueTrim({
        redirectUrl,
        submissionSuccessMessage,
        headingMessage,
        privacyPolicyLink,
        emailCustomMessage,
        existingBuyerMessage,
        existingBuyerRedirectUrl,
      }),
      buyerFormFields: arrayFieldValueTrim(buyerFormFields || [], ['title']),
      redirectionSettings: {
        ...redirectionSettings,
        ...objectValueTrim({
          applicationLoginPageUrl,
          customInstructions,
          demoDocumentUrl,
          demoVideoUrl,
        }),
        testingCredentials: {
          ...testingCredentials,
          ...objectValueTrim({
            ...testingCredentials,
          }),
        },
      },
    },
    productDetails: {
      ...productDetails,
      ...objectValueTrim({
        productName,
      }),
    },
    productDimensions: {
      ...productDimensions,
      dimensions: arrayFieldValueTrim(dimensions, [
        'name',
        'label',
        'labelDescription',
        'additionalUsageDescription',
      ]),
      additionalDimensions: arrayFieldValueTrim(additionalDimensions || [], [
        'name',
        'label',
        'labelDescription',
        'additionalUsageDescription',
      ]),
    },
  } as FlyoutProductSections
}

export const sendFlyoutProduct =
  ({
    partnerId,
    isAutoSave,
    productForm,
    pageMarkerMapping,
    page,
    productId,
    status,
    callBackFlyoutProduct,
    isClear,
    redirectToListing,
    cloneproduct,
  }: SendFlyoutProductProps) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      !isAutoSave &&
        (await dispatch(
          startLoading(LoadingTypes.SUBMIT_FLYOUT_PRODUCT_DETAIL)
        ))
      const getProductStateData =
        getState()?.flyoutProductInCreation.currentProductInCreation.productForm
      const isFormUpdated = isChangeInProductData(
        productForm,
        getProductStateData
      )
      const productFormData = getTrimedValueProductForm(productForm)
      const sendProductPayload = processFlyoutProduct({
        productForm: productFormData,
        isAutoSave,
        pageMarkerMapping,
        page,
        isFormUpdated,
        status,
        productId,
      })
      const productPayload = JSON.parse(
        JSON.stringify(sendProductPayload).replaceAll('\\"', '\\\\\\"')
      )

      const response = await putFlyOutProductFormData({
        data: productPayload,
        partnerId,
        productId,
      })
      if (response) {
        const camelizedProductData = JSON.parse(
          JSON.stringify(get(camelize(response), 'data') || {}).replaceAll(
            '\\\\"',
            '"'
          )
        )
        const transformedProduct = await transformGetProductData({
          flyoutProductData: camelizedProductData.detailsJson,
          lockMetadata: camelizedProductData.lockMetadata,
        })
        await dispatch(
          setOtherProductInfo({
            error: camelizedProductData?.error,
            updatedAt: camelizedProductData?.updatedAt,
          })
        )
        await dispatch(setProductFormData(transformedProduct))

        const successMessage = cloneproduct
          ? productCloneSuccess
          : transformedProduct?.completed?.value
          ? productCreationSuccess
          : draftProductCreationSuccess
        if (!isAutoSave || (isAutoSave && isClear) || cloneproduct) {
          await dispatch(
            updateAppAlert({
              message: isClear
                ? clearDraftProductCreationSuccess
                : successMessage,
              messageType: 'SUCCESS',
              autoClose: true,
            })
          )
        }
        if (transformedProduct?.completed?.value && redirectToListing) {
          await dispatch(
            actionTypeWrapper(CloudType.AWS, clearProductsListing())
          )
          await dispatch(getProducts(partnerId, CloudType.AWS))
          await redirectToListing()
        }
      }

      if (
        (isEmpty(productId) || status === 'lockAcquire') &&
        response &&
        callBackFlyoutProduct
      ) {
        await callBackFlyoutProduct(response.data.id)
      }
    } catch (error: any) {
      const allOtherPageMarkersTrue = getAllOtherPageMarkersTrue({
        sectionName: page,
        pageMarkerMapping,
        pageMarker: productForm[FlyoutProductAddEditSectionName.PAGE_MARKER],
        isMigrated:
          productForm[FlyoutProductAddEditSectionName.CLOUD_PRODUCT_DETAILS]
            .listingType === FlyoutListingProductType.MIGRATED,
      })
      if (!isAutoSave && allOtherPageMarkersTrue) {
        const errorMessage = getErrorMessages(['error Occured '])(
          error.response as AxiosResponse<ErrorResponse>
        )
        await dispatch(setErrorOnFinalSubmit(errorMessage))
      }
      if (!isAutoSave) {
        const errMessage: string = isClear
          ? clearDraftProductCreationFail
          : draftProductCreationFail
        const errorMessage = getErrorMessages([errMessage])(
          error.response as AxiosResponse<ErrorResponse>
        )

        await dispatch(stopLoading(LoadingTypes.SUBMIT_FLYOUT_PRODUCT_DETAIL))
        if (isEmpty(status)) {
          await dispatch(
            updateAppAlert({
              message: errorMessage,
              messageType: 'ERROR',
              autoClose: false,
            })
          )
        }
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      } else {
        return error
      }
    } finally {
      !isAutoSave &&
        (await dispatch(stopLoading(LoadingTypes.SUBMIT_FLYOUT_PRODUCT_DETAIL)))
    }
  }

export const processFlyoutProduct = ({
  productForm,
  isAutoSave,
  pageMarkerMapping,
  page,
  isFormUpdated,
  status,
  productId,
  isEdit,
  existingBuyerFormField,
}: {
  productForm: FlyoutProductSections
  isAutoSave?: boolean
  pageMarkerMapping?: Record<string, string>
  page?: FlyoutProductAddEditSectionName
  isFormUpdated?: boolean
  status?: string
  productId?: string
  isEdit?: boolean
  existingBuyerFormField?: Record<string, BuyerFormFieldsBackend>
}) => {
  const {
    bgColor,
    buttonColor,
    buttonTextColor,
    buyerFormFields,
    ...restInfoCollectionData
  } = productForm[FlyoutProductAddEditSectionName.LANDING_PAGE_DETAILS]

  const { dimensions, additionalDimensions, ...restProductDimensions } =
    productForm[FlyoutProductAddEditSectionName.PRODUCT_DIMENSIONS]

  const updatedSupportResources =
    productForm.generalInformation?.resourceUrls?.filter(
      (resource: any) => resource.Text !== '' && resource.Url !== ''
    )

  const updatedDimensions = dimensions
    .filter((dimension: any) => dimension.name !== '')
    .map((dimension: any) => {
      const { checkBoxAdditionalUsageAllowed, ...updatedDimension } = dimension
      return {
        ...updatedDimension,
      }
    })

  const updatedAdditionalDimensions = (additionalDimensions || []).map(
    (dimension: any) => {
      const {
        id,
        checkBoxAdditionalUsageAllowed,
        ...aditionalDimensionsUpdated
      } = dimension
      return {
        ...aditionalDimensionsUpdated,
        ...(isEdit && id ? { id: id } : {}),
      }
    }
  )

  const transformedBuyerField = buyerFormFields?.reduce((acc, field, index) => {
    const {
      title,
      dataType,
      isRequired,
      validationType,
      minLength,
      maxLength,
      id,
    } = field

    acc[title] = {
      displayOrder: index + 4,
      title,
      placeHolder: title,
      dataType,
      isCustom: true,
      isRequired,
      validationType,
      maxLength,
      minLength,
      ...(isEdit && id ? { id: id } : {}),
    }
    return acc
  }, {} as Record<string, BuyerFormFieldsBackend>)

  const allOtherPageMarkersTrue =
    page &&
    pageMarkerMapping &&
    getAllOtherPageMarkersTrue({
      sectionName: page,
      pageMarkerMapping,
      pageMarker: productForm[FlyoutProductAddEditSectionName.PAGE_MARKER],
      isMigrated:
        productForm[FlyoutProductAddEditSectionName.CLOUD_PRODUCT_DETAILS]
          .listingType === FlyoutListingProductType.MIGRATED,
    })

  const camelizeProductData = {
    ...productForm,
    generalInformation: {
      ...productForm.generalInformation,
      resourceUrls: updatedSupportResources,
      ...(isEdit &&
      productForm?.[FlyoutProductAddEditSectionName.GENERAL_INFORMATION]
        .eula === EulaType.STANDARD
        ? { eulaUrl: '' }
        : {}),
    },
    infoCollectionFormMetaData: {
      ...restInfoCollectionData,
      bgColor: convertRGBAAlpha(bgColor, true),
      buttonColor: convertRGBAAlpha(buttonColor, true),
      buttonTextColor: convertRGBAAlpha(buttonTextColor, true),
    },
    buyerFormFields: {
      ...(!isEdit
        ? initialProductJson.buyerFormFields
        : existingBuyerFormField),
      ...transformedBuyerField,
    },
    productDimensions: {
      ...restProductDimensions,
      dimensions: [...updatedDimensions, ...updatedAdditionalDimensions],
    },
    ...(!isEdit
      ? {
          lockMetadata: {
            ...productForm.lockMetadata,
            ...(isFormUpdated || status === 'lockAcquire' || isEmpty(productId)
              ? { lastActivityAt: DateTime.now().toUTC().toISO() }
              : {}),
            editingEnabled: status === 'lockAcquire' || isEmpty(productId),
          },
          ...(!isAutoSave &&
            pageMarkerMapping &&
            page && {
              pageMarker: {
                ...productForm.pageMarker,
                [camelize(pageMarkerMapping[page])]: true,
              },
              completed: {
                value: allOtherPageMarkersTrue,
              },
            }),
        }
      : {}),
  }

  const { productDimensions, generalInformation, notificationRecipients } =
    camelizeProductData

  let updatedNotificationRecipients = {}
  Object.keys(notificationRecipients).forEach(keyName => {
    const kebabCaseKeyName = convertToKebabCase(keyName)
    updatedNotificationRecipients = {
      ...updatedNotificationRecipients,
      [kebabCaseKeyName]:
        notificationRecipients[
          keyName as string as keyof NotificationRecipients
        ],
    }
  })

  const snakeizeDimensions = productDimensions?.dimensions
    ? productDimensions?.dimensions.map((dimension: any) => {
        const {
          price1m,
          price12m,
          price24m,
          price36m,
          isExisting,
          ...restProps
        } = dimension

        const priceObj = isEdit
          ? {
              ...(isNonEmptyNumber(price1m) ? { price_1m: price1m } : {}),
              ...(isNonEmptyNumber(price12m) ? { price_12m: price12m } : {}),
              ...(isNonEmptyNumber(price24m) ? { price_24m: price24m } : {}),
              ...(isNonEmptyNumber(price36m) ? { price_36m: price36m } : {}),
            }
          : {
              price_1m: price1m || null,
              price_12m: price12m || null,
              price_24m: price24m || null,
              price_36m: price36m || null,
            }
        return {
          ...snakeize(restProps),
          ...priceObj,
        }
      })
    : []

  const updatedResourceUrls = generalInformation.resourceUrls
    ? generalInformation.resourceUrls.map((resources: any) => {
        const { Text, Url } = resources
        return {
          Text: Text,
          Url: Url,
        }
      })
    : []
  const snakeizeProductInfo = { ...snakeize(camelizeProductData) }
  return {
    ...snakeizeProductInfo,
    product_dimensions: {
      ...snakeize(productDimensions),
      dimensions: snakeizeDimensions,
    },
    general_information: {
      ...snakeizeProductInfo.general_information,
      resource_urls: updatedResourceUrls,
    },
    notification_recipients: {
      ...updatedNotificationRecipients,
    },
  }
}

export const getAllOtherPageMarkersTrue = ({
  pageMarker,
  pageMarkerMapping,
  sectionName,
  isMigrated,
}: {
  pageMarker?: pageMarkerType
  pageMarkerMapping: Record<string, string>
  sectionName: FlyoutProductAddEditSectionName
  isMigrated: boolean
  isHostWithLabra?: boolean
}) =>
  (pageMarker ? Object.keys(pageMarker) : []).every(marker => {
    const currentSectionPageMarker = camelize(pageMarkerMapping[sectionName])

    if (isMigrated) {
      return marker !== currentSectionPageMarker && marker !== 'page2'
        ? pageMarker && pageMarker[marker]
        : true
    }

    return marker !== currentSectionPageMarker
      ? pageMarker && pageMarker[marker]
      : true
  })

const isChangeInProductData = (
  productData: FlyoutProductSections,
  getProductStateData: FlyoutProductSections
) => {
  const { lockMetadata: metaData, ...restProductData } = productData
  const { lockMetadata, ...restGetProductStateData } = getProductStateData
  return !isEqual(restProductData, restGetProductStateData)
}

const transformGetProductData = async ({
  flyoutProductData,
  lockMetadata,
  isEdit,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  flyoutProductData: any
  lockMetadata?: any
  isEdit?: boolean
}) => {
  const { buyerFormFields } = flyoutProductData
  const excludedFields = ['email', 'firstName', 'companyName']
  const customFields = Object.keys(buyerFormFields || {})
    .filter(
      fieldName =>
        typeof buyerFormFields[fieldName] === 'object' &&
        buyerFormFields[fieldName] !== null &&
        !excludedFields.includes(fieldName)
    )
    .map(fieldName => {
      const typedFieldInfo = buyerFormFields[
        fieldName
      ] as FlyoutProductBuyerFormFields
      return {
        title: typedFieldInfo?.title,
        dataType: typedFieldInfo?.dataType,
        isRequired: typedFieldInfo?.isRequired,
        validationType: typedFieldInfo?.validationType,
        minLength: typedFieldInfo?.minLength,
        maxLength: typedFieldInfo?.maxLength,
        ...(typedFieldInfo?.id ? { id: typedFieldInfo?.id } : {}),
        ...(isEdit ? { isExisting: true } : {}),
      }
    })

  const selectedProductType = flyoutProductData?.productDetails?.productType
  const isSubscription = selectedProductType === PlanType.SAAS_SUBSCRIPTION

  const dimensions = flyoutProductData?.productDimensions?.dimensions || []

  const additionalUsageDimensionsList = dimensions.filter(
    (dimension: ContractDimension) =>
      !isSubscription ? dimension.isAdditionalUsageAllowed : true
  )
  const contractDimensionsList = isSubscription
    ? []
    : dimensions.filter(
        (dimension: ContractDimension) => !dimension.isAdditionalUsageAllowed
      )

  const contractDimensionsListWithCheckbox = contractDimensionsList.map(
    (dimension: ContractDimension) => {
      const foundDimension = additionalUsageDimensionsList.find(
        (d: any) => d.name === dimension.name
      )
      return {
        ...dimension,
        checkBoxAdditionalUsageAllowed: !!foundDimension,
        ...(isEdit ? { isExisting: true } : {}),
      }
    }
  )

  const additionalUsageDimensionsListWithCheckbox =
    additionalUsageDimensionsList.map((dimension: ContractDimension) => {
      const foundDimension = contractDimensionsList.find(
        (d: any) => d.name === dimension.name
      )
      return {
        ...dimension,
        checkBoxAdditionalUsageAllowed: !!foundDimension,
        ...(isEdit ? { isExisting: true } : {}),
      }
    })

  const infoCollection = { ...flyoutProductData.infoCollectionFormMetaData }
  const { bgColor, buttonColor, buttonTextColor } =
    flyoutProductData.infoCollectionFormMetaData
  Object.keys(infoCollection).forEach(key => {
    if (
      Object.keys(prePopulatedFieldLandingPage).includes(key) &&
      !Boolean(infoCollection[key])
    ) {
      infoCollection[key] = prePopulatedFieldLandingPage[key]
    }
  })

  return {
    ...flyoutProductData,
    infoCollectionFormMetaData: {
      ...flyoutProductData.infoCollectionFormMetaData,
      ...infoCollection,
      buyerFormFields: customFields,
      bgColor: convertRGBAAlpha(bgColor),
      buttonColor: convertRGBAAlpha(buttonColor),
      buttonTextColor: convertRGBAAlpha(buttonTextColor),
    },
    ...(!isEdit
      ? {
          agreement: {
            ...(!isEmpty(flyoutProductData?.agreement?.terms)
              ? flyoutProductData?.agreement
              : camelize(flyOutProductAgreementTerms)),
          },
        }
      : {}),
    productDimensions: {
      ...flyoutProductData.productDimensions,
      dimensions: contractDimensionsListWithCheckbox?.length
        ? contractDimensionsListWithCheckbox
        : [PRODUCT_DIMENSIONS_ZERO_VALUE],
      additionalDimensions: additionalUsageDimensionsListWithCheckbox,
    },
    ...(!isEdit
      ? {
          lockMetadata: {
            ...lockMetadata,
          },
        }
      : {}),
  }
}

interface UpdateFlyoutProductProps {
  productForm: FlyoutProductSections
  partnerId: string
  productId?: string
  callBackFlyoutProduct?: () => void
}

export const updateFlyoutProduct =
  ({
    partnerId,
    productForm,
    productId,
    callBackFlyoutProduct,
  }: UpdateFlyoutProductProps) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const productName =
      productForm?.[FlyoutProductAddEditSectionName?.GENERAL_INFORMATION]?.title

    try {
      await dispatch(startLoading(LoadingTypes.SUBMIT_FLYOUT_PRODUCT_DETAIL))
      const {
        productForm: productFormInfo,
        auditJson,
        ...restInfo
      } = getState()?.flyoutProductInCreation?.currentProductDetail
      const productFormData = getTrimedValueProductForm(productForm)
      const { product_details, ...restProductDetails } = processFlyoutProduct({
        productForm: productFormData,
        productId,
        isEdit: true,
        existingBuyerFormField: productFormInfo?.buyerFormFields,
      })
      const sendProductPayload = {
        audit_json: auditJson,
        ...snakeize(restInfo),
        ...restProductDetails,
        product_name: restProductDetails?.general_information?.title,
      }

      const productPayload = JSON.parse(
        JSON.stringify(sendProductPayload).replaceAll('\\"', '\\\\\\"')
      )

      const response = await patchFlyOutProductFormData({
        data: productPayload,
        partnerId,
        productId,
      })

      if (response) {
        await dispatch(
          setProductDetailFormData({
            ...getState()?.flyoutProductInCreation?.currentProductDetail,
            edit: response?.data?.edit,
            awsChangeSetStatus: response?.data?.awsChangeSetStatus,
            error: response?.data?.error,
            updatedAt: response?.data?.updated_at,
          })
        )

        const successMessage = `Edit request for "${productName}" is in progress`

        await dispatch(
          updateAppAlert({
            message: successMessage,
            messageType: 'SUCCESS',
            autoClose: true,
          })
        )
      }
    } catch (error: any) {
      await dispatch(stopLoading(LoadingTypes.SUBMIT_FLYOUT_PRODUCT_DETAIL))
      await dispatch(
        updateAppAlert({
          message: `"${productName}" listing could not be edited. Please try again in some time`,
          messageType: 'ERROR',
          autoClose: false,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      callBackFlyoutProduct && callBackFlyoutProduct()
      await dispatch(stopLoading(LoadingTypes.SUBMIT_FLYOUT_PRODUCT_DETAIL))
    }
  }

export const clearProductDetail = () => ({
  type: FlyoutProductForm.CLEAR_FLYOUT_PRODUCT_DETAIL_FORM,
})

export const flyoutProductSyncWithAWS =
  ({
    partnerId,
    productId,
    productName,
  }: {
    partnerId: string
    productId: string
    productName: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      await dispatch(startLoading(LoadingTypes.PRODUCT_SYNC_WITH_CLOUD))

      await patchFlyOutProductSync({
        partnerId,
        productId,
      })

      const successMessage = `"${productName}" successfully synced with AWS.`

      await dispatch(
        updateAppAlert({
          message: successMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      await dispatch(stopLoading(LoadingTypes.PRODUCT_SYNC_WITH_CLOUD))
      await dispatch(
        updateAppAlert({
          message: `"${productName}" sync failed with AWS. Please try again in some time.`,
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.PRODUCT_SYNC_WITH_CLOUD))
    }
  }
