import axios, { AxiosError } from 'axios'
import { isTestEnvironment } from '../utils/testing'
import {
  JWTRegex,
  crmServiceUrl,
  engineServiceUrl,
  engineServiceV2Url,
  fetchAwsDimensionsCategoriesListUrl,
  fetchCountriesListS3Url,
  identityServiceUrl,
  oktaDomain,
} from '../utils/constants'
import {
  G2ReviewResponseType,
  PartnerData,
  PatchPartnerAWSDetails,
  UpdateG2Review,
} from '../modules/partner/action'
import { newrelicErrLogger } from '../utils/ErrorHandler'
import { GetUsersParams } from '../../oppsync/modules/userList/action'
import { UserState } from '../../oppsync/modules/userList/reducer'
import { OnboardingState } from '../modules/onboarding/reducer'
import { GetPartnerObjectsParams } from '../../oppsync/modules/salesPipeline/partnerObjects/actions'
import { CRMInfo } from '../modules/onboarding/crmAuth/action'
import { getSecureKey, getServiceNameFromUrl } from '../utils/helperFunctions'
import { UserData } from '../components/Register/RegisterUI'
import { IdentityServiceUser, UserProfile } from '../modules/userProfile/action'
import { snakeize } from 'casing'
import { SyncObjectType } from '../../oppsync/modules/salesPipeline/syncObject/reducer'

export const API = axios.create()
export const setOnboardingWelcome = (isWelcome: boolean) => {
  try {
    localStorage.setItem('onboardingWelcome', JSON.stringify(isWelcome))
  } catch (err) {
    console.warn('localStorage is not available.')
    newrelicErrLogger(err as Error, {
      message: err,
    })
    return undefined
  }
}

if (!isTestEnvironment()) {
  API.interceptors.request.use(config => {
    const serviceName = getServiceNameFromUrl(config.url || '')
    const secureKey = getSecureKey()

    if (serviceName && secureKey) {
      if (new RegExp(JWTRegex).test(secureKey)) {
        config.headers[
          'Internal-Authorization'
        ] = `Bearer-${serviceName} ${secureKey}`
      }
    } else {
      if (!Object.hasOwn(config.headers, 'Authorization')) {
        const token = localStorage.getItem('auth0-access-token')
        config.headers['Authorization'] = `Bearer ${token}`
      }
    }

    return config
  })

  API.interceptors.response.use(
    res => {
      return res
    },
    (error: AxiosError) => {
      const requestId = error.response?.headers['x-request-id']
      const { url: requestURL, method: requestMethod } =
        error.response?.config || {}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newrelicErrLogger((error.response?.data as any)?.message || error, {
        requestId: requestId || 'No Request Id',
        requestURL: requestURL || 'NO Request URL',
        requestMethod: requestMethod || 'NO Request Method',
      })
      return Promise.reject(error)
    }
  )
}

//Fetch list of CRMS
export const fetchCRMs = () => API.get(`${crmServiceUrl}/auth_defintions`)

//GET Partner Data
export const getPartnerDataFromEngineService = (
  partnerId: string,
  fetchOktaUser: boolean
) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}`, {
    params: {
      fetch_okta_users: fetchOktaUser,
    },
  })

//GET Partner Data from Identity Service
export const getPartnerDataFromIdentityService = (
  partnerId: string,
  fetchOwner: boolean
) =>
  API.get(`${identityServiceUrl}/partners/${partnerId}`, {
    params: fetchOwner && {
      include: 'PARTNER_OWNER',
    },
  })

//POST CRM Auth
export const postCRMAuthData = (authData: unknown, partnerId: string) =>
  API.post(`${engineServiceUrl}/partners/${partnerId}/crms`, authData, {
    params: {
      check_connection: true,
    },
  })
export const patchCRMAuthData = (crmId: string, authData: unknown) =>
  API.patch(`${crmServiceUrl}/crms/${crmId}`, authData, {
    params: {
      check_connection: true,
    },
  })

//GET G2 review status
export const getG2ReviewData = async (
  partnerId: string
): Promise<G2ReviewResponseType[]> => {
  const response = await API.get(
    `${identityServiceUrl}/partners/${partnerId}/customer-reviews`
  )

  const reviewData: G2ReviewResponseType[] = response.data.results.map(
    (item: any) => ({
      id: item.id,
      completed: item.completed,
      openedAt: item.opened_at,
      reviewType: item.review_type,
      partnerId: item.partner_id,
    })
  )

  return reviewData
}
//PUT App Mappings
export function putAppMappings(partnerId: string, CRMInfo: CRMInfo) {
  API.put(`${engineServiceUrl}/partners/${partnerId}/app_mappings`, CRMInfo)
}
//GET CRM Auth data
export const getCRMAuth = (crmId: string) =>
  API.get(`${crmServiceUrl}/crms/${crmId}?includes=creds,crm_app_creds`)

export const getCRMType = (crmId: string) =>
  API.get(`${crmServiceUrl}/crms/${crmId}`)

export const fetchCRMTables = (crm_id: string) =>
  API.get(`${crmServiceUrl}/crms/${crm_id}/tables`)

export const fetchCRMTableFields = (
  crmId: string,
  tableName: string,
  awsFieldsVersion: string
) =>
  API.get(`${crmServiceUrl}/crms/${crmId}/tables/${tableName}/fields`, {
    params: {
      awsFieldsVersion: awsFieldsVersion,
    },
  }).then(({ data }) => data)

export const fetchACEFields = (objectType: string, aceFieldsVersion: string) =>
  API.get(`${engineServiceUrl}/ace-fields`, {
    params: {
      object_type: objectType,
      ace_fields_version: aceFieldsVersion,
    },
  })

export const fetchOnboarding = (partnerId: string) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/onboarding`)

export const fetchMappingData = (partnerId: string) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/mappings`)

export const fetchPartnersStatitics = () =>
  API.get(`${engineServiceUrl}/partners-statistics`)

export const fetchAllPartners = (pageSize: number, pageNumber: number) =>
  API.get(`${engineServiceUrl}/partners`, {
    params: {
      limit: pageSize,
      offset: pageNumber,
      fetch_okta_users: true,
      onboardings: true,
      includes: 'creds,crm_app_creds',
    },
  })

export const fetchAllPartnersFromIdentityService = (
  pageSize: number,
  pageNumber: number
) =>
  API.get(`${identityServiceUrl}/partners`, {
    params: {
      limit: pageSize,
      offset: pageNumber,
      includes: 'PARTNER_OWNER',
    },
  })

export const searchPartnerList = (
  searchQuery: string,
  pageSize: number,
  pageNumber: number
) => {
  return API.get(`${engineServiceUrl}/partners`, {
    params: {
      limit: pageSize,
      offset: pageNumber,
      user_name: searchQuery,
      fetch_okta_users: true,
      onboardings: true,
      includes: 'creds,crm_app_creds',
    },
  })
}

export const searchPartnerListFromIdentityService = (
  searchQuery: string,
  pageSize: number,
  pageNumber: number
) => {
  return API.get(`${identityServiceUrl}/partners`, {
    params: {
      limit: pageSize,
      offset: pageNumber,
      query: searchQuery,
      includes: 'PARTNER_OWNER',
    },
  })
}

export const fetchUsers = (args: GetUsersParams) => {
  const { partnerId, pageSize, pageNumber } = args
  return API.get(`${engineServiceUrl}/partners/${partnerId}/users`, {
    params: {
      limit: pageSize,
      page_number: pageNumber,
    },
  })
}

export const fetchUsersFromIdentityService = (args: GetUsersParams) => {
  const { partnerId, pageSize, pageNumber } = args
  return API.get(`${identityServiceUrl}/partners/${partnerId}/users`, {
    params: {
      take: pageSize,
      skip: ((pageNumber || 1) - 1) * (pageSize || 10),
    },
  })
}

export const fetchPartnerObjects = (args: GetPartnerObjectsParams) => {
  const {
    partnerId,
    objectType,
    pageSize,
    pageNumber,
    syncStatus,
    objectsIds,
  } = args

  return API.get(`${engineServiceUrl}/partners/${partnerId}/objects`, {
    params: {
      object_type: objectType,
      limit: pageSize,
      page_number: pageNumber,
      last_sync_state: syncStatus,
      object_ids: objectsIds,
    },
  })
}

export const fetchPartnerObject = (
  partnerId: string,
  objectId: string,
  relations: string,
  objectType: CRMObjectType
) => {
  return API.get(
    `${engineServiceUrl}/partners/${partnerId}/objects/${objectId}`,
    {
      params: {
        relations,
        object_type: objectType,
      },
    }
  )
}

export const fetchPartnerObjectsStatistics = (partnerId: string) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/statistics`)

export const fetchSyncHistory = (
  partnerId: string,
  pageSize: number,
  pageNumber: number
) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/syncs`, {
    params: {
      limit: pageSize,
      page_number: pageNumber,
    },
  })

export const fetchLastSyncDetails = (partnerId: string) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/syncs`, {
    params: {
      limit: 1,
      page_number: 1,
    },
  })
export const getCloudConfiguration = (partnerId: string) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/cloud-configuration`)
export const postSupportData = (partnerId: string, data: unknown) =>
  API.post(`${engineServiceUrl}/partners/${partnerId}/support-tickets`, data)

export const saveCrmAceMapping = (
  partnerId: string,
  data: unknown,
  updateStatus = true
) =>
  API.put(`${engineServiceUrl}/partners/${partnerId}/mappings`, data, {
    params: {
      update_status: updateStatus,
    },
  })

export const postPartnerMappings = (partnerId: string) =>
  API.post(`${engineServiceUrl}/partners/${partnerId}/mappings`)

export const patchUserProfile = (
  partnerId: string,
  oktaUserId: string,
  profile: UserProfile
) =>
  API.patch(
    `${engineServiceUrl}/partners/${partnerId}/profiles/${oktaUserId}`,
    snakeize(profile)
  )

export const patchUserProfileIdentityService = (
  partnerId: string,
  userId: string,
  profile: UserProfile
) =>
  API.patch(
    `${identityServiceUrl}/partners/${partnerId}/users/${userId}`,
    profile
  )

export const fetchUserProfile = (
  partnerId: string,
  userId: string
): Promise<IdentityServiceUser> =>
  API.get(`${identityServiceUrl}/partners/${partnerId}/users/${userId}`).then(
    ({ data }) => data as IdentityServiceUser
  )

export const updateUserFromIdentityService = (
  partnerId: string,
  data: unknown,
  userId?: string
) =>
  API.patch(`${identityServiceUrl}/partners/${partnerId}/users/${userId}`, data)

export const loginWithCredentials = (username: string, password: string) => {
  return API.post(`https://${oktaDomain}/api/v1/authn`, {
    username: username,
    password: password,
  })
}
export const registerWithCredentials = (data: UserData) => {
  return API.post(`${engineServiceV2Url}/partners`, data)
}
export const recoverPasswordWithEmail = (username: string) => {
  return API.post(`https://${oktaDomain}/api/v1/authn/recovery/password`, {
    username: username,
    factorType: 'EMAIL',
  })
}
export const patchCRMSchema = (
  crmId: string,
  crmSchema: Record<string, never>
) => API.patch(`${crmServiceUrl}/crms/${crmId}`, { crm_schema: crmSchema })

export const updateUserData = (
  partnerId: string,
  userId: string,
  data: UserState
) =>
  API.patch(`${engineServiceUrl}/partners/${partnerId}/users/${userId}`, data)

export const patchPartnerEngineService = (
  partnerId: string,
  data: PartnerData
) => API.patch(`${engineServiceUrl}/partners/${partnerId}`, data)

export const patchPartnerIdentityService = (
  partnerId: string,
  data: Partial<PartnerData>
) => API.patch(`${identityServiceUrl}/partners/${partnerId}`, data)

export const patchPartnerAWSDetails = (
  partnerId: string,
  data: PatchPartnerAWSDetails
) => API.patch(`${engineServiceUrl}/partners/${partnerId}/aws-details`, data)

export const uploadPartnerCloudConfig = (
  partnerId: string,
  data: FormData,
  progressCallback?: (progressEvent: any) => void
) =>
  API.put(
    `${engineServiceUrl}/partners/${partnerId}/cloud-configuration`,
    data,
    {
      onUploadProgress: progressCallback,
    }
  )

export const patchOnboarding = (partnerId: string, data: OnboardingState) =>
  API.patch(`${engineServiceUrl}/partners/${partnerId}/onboardings`, data)

export const triggerManualSync = (partnerId: string) =>
  API.post(`${engineServiceUrl}/partners/${partnerId}/syncs`)

export const fetchSyncReport = (
  partnerId: string,
  pageSize: number,
  pageNumber: number
) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/sync-results`, {
    params: {
      limit: pageSize,
      page_number: pageNumber,
    },
  })

export const getCheckS3Connection = (
  partnerId: string,
  env: 'production' | 'staging'
) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/check-s3connection`, {
    params: {
      environment: env,
    },
  })

export const postAddUsersData = (data: unknown, partnerId: string) =>
  API.post(`${engineServiceUrl}/partners/${partnerId}/users`, data)

export const postAddNewPartnerData = (data: unknown) =>
  API.post(`${identityServiceUrl}/partners`, data)

export const inviteUsersIdentityService = (data: unknown, partnerId: string) =>
  API.post(`${identityServiceUrl}/partners/${partnerId}/invitations`, data)

export const postResendInvite = (partnerId: string, userId: string) =>
  API.post(
    `${engineServiceUrl}/partners/${partnerId}/users/${userId}/lifecycle/reactivate`
  )

export const resendInvitationIdentityService = (
  partnerId: string,
  userId: string
) =>
  API.post(
    `${identityServiceUrl}/partners/${partnerId}/users/${userId}/email_verifications`
  )

export const deleteUser = (partnerId: string, userId: string) =>
  API.delete(`${engineServiceUrl}/partners/${partnerId}/users/${userId}`)

export const deleteUserIdenityService = (partnerId: string, userId: string) =>
  API.delete(`${identityServiceUrl}/partners/${partnerId}/users/${userId}`)

export const deleteSyncsData = (partnerId: string) =>
  API.delete(`${engineServiceUrl}/partners/${partnerId}/syncs`)

export const deleteCRM = (partnerId: string, crmId: string) =>
  API.delete(`${engineServiceUrl}/partners/${partnerId}/crms/${crmId}`)

export const deleteCRMFromCRMService = (crmId: string) =>
  API.delete(`${crmServiceUrl}/crms/${crmId}`)

export const postCheckCrmConnection = (crm_id: string) =>
  API.post(`${crmServiceUrl}/crms/${crm_id}/check-salesforce-app-connection`)

export const fetchAwsCountriesList = () => axios.get(fetchCountriesListS3Url)

export const fetchAwsDimensionsCategoriesList = () =>
  axios.get(fetchAwsDimensionsCategoriesListUrl)

export const getG2ReviewUrl = (partnerId: string, userId: string) =>
  API.get(
    `${identityServiceUrl}/partners/${partnerId}/users/${userId}/review_urls`
  )

export const putCustomerReviewG2 = (partnerId: string, data: UpdateG2Review) =>
  API.put(
    `${identityServiceUrl}/partners/${partnerId}/customer-reviews/g2`,
    data
  )
