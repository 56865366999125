import { setOnboarding } from '../onboarding/actions'
import { Dispatch } from 'redux'
import {
  getCheckS3Connection,
  getCloudConfiguration,
  getPartnerDataFromEngineService,
  getPartnerDataFromIdentityService,
  patchOnboarding,
  patchPartnerEngineService,
  patchPartnerAWSDetails,
  patchPartnerIdentityService,
  putCustomerReviewG2,
  getG2ReviewData,
} from '../../api'
import {
  actionTypeWrapper,
  actionTypeWrapperCurried,
} from '../../utils/actionTypeWrapper'
import { camelize, snakeize } from 'casing'
import { startLoading, stopLoading } from '../loading/actions'
import { syncResponse } from '../../../oppsync/modules/syncHistory/action'
import { fetchLastSyncDetails } from '../../api'
import { updateAppAlert } from '../appAlert/actions'
import {
  SyncStatusFail,
  RequestFailureMessage,
  PartnerUpdateError,
  PartnerUpdateSuccess,
  S3ConnectionSuccessMessage,
  S3ConnectionFailureMessage,
} from '../../utils/messagesContants'
import { PartnerOwner } from '../allPartners/reducer'
import { AxiosError } from 'axios'
import { LoadingTypes } from '../loading/reducer'
import { OnboardingState } from '../onboarding/reducer'
import { ProductType } from './reducer'
import { errorLogger } from '../../utils/errorLogger'
import { CloudType } from '../types'
import { RootState } from '../../../store'
import { getErrorMessages } from '../../utils/error'
import { TestButtonStatus } from '@labrav/react-components/lib/@types/components/TestButtonComponent'
import { AuthProvider } from '../../../oppsync/modules/userList/action'
import { isEmpty } from 'lodash'
import { CRMTypes } from '../../utils/constants'
import {
  SubscribeProducts,
  Users,
} from '../../../admin/modules/NewCustomers/reducer'
interface ConnectionJson {
  success: boolean
  error?: string
}
export enum AuthProtocol {
  PASSWORD = 'PASSWORD',
  EXTERNAL = 'EXTERNAL',
}
export type Product = 'oppsync' | 'flyout' | 'cloudfaas'
export enum CloudProvider {
  AWS = 'aws',
  GCP = 'gcp',
  AZ = 'azure',
  REDHAT = 'redhat',
}

export type ActiveProducts = Product[] | string[]
export interface PartnerData {
  partnerId: string
  id?: string
  crmId?: string
  iamArn?: string
  policyName?: string
  iamArnStaging?: string
  createdAt?: string
  updatedAt?: string
  contactEmail?: string
  name?: string
  companyName?: string
  syncPaused?: boolean
  partnerOwner?: PartnerOwner
  spmsId?: string
  aceS3BucketPath?: string
  awsRegion?: string
  firstSyncDate?: string
  activeProducts?: ActiveProducts
  activeProductsWithCloud?: string[]
  activeCloudProvider?: CloudType
  isInternal?: boolean
  awsSecurityPolicy?: string
  partnershipTier?: string
  partnershipType?: string
  isIsv?: boolean
  isSrrp?: boolean
  accelerateProgram?: boolean
  industry?: string | null
  acceptedTermsAndConditions?: boolean
  prdAceS3BucketPath?: string
  stgAceS3BucketPath?: string
  websiteUrl?: string
  awsFieldsVersion?: string
  iamPolicyStaging?: string
  iamPolicyProduction?: string
  allowStoringPii?: boolean
  s3Status?: {
    production: TestButtonStatus
    staging: TestButtonStatus
  }
  hasAWSCustomCatalog?: boolean
  authProtocol?: AuthProtocol
  crmType?: CRMTypes
  subscribedProducts?: Partial<SubscribeProducts>
  quotationLink?: string
  internalNotes?: string
  numberOfSeats?: number
  includeLabraBot?: boolean
  users?: Users[]
  G2Review?: boolean
  G2ReviewList?: G2ReviewResponseType[]
}

export type ProductSubscription = {
  cloudProviders: CloudProvider[]
}
export interface SubscribedProducts {
  OPPSYNC?: ProductSubscription
  FLYOUT?: ProductSubscription
}

export interface PatchPartnerAWSDetails {
  iam_policies: {
    environment: string
    policy: object
  }[]
}

export interface ToggleSyncData {
  syncPaused: boolean
}

export enum PartnerActionTypes {
  SET_PARTNER = 'SET_PARTNER',
  SET_SELECTED_PARTNER_ID = 'SET_SELECTED_PARTNER_ID',
  GET_PARTNER_FAILED = 'GET_PARTNER_FAILED',
  PARTNER_LOADING = 'PARTNER_LOADING',
  SET_CRM_ID = 'SET_CRM_ID',
  PARTNER_CLEANUP = 'PARTNER_CLEANUP',
  SET_LAST_SYNC = 'SET_LAST_SYNC',
  SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT',
  CLEAR_CURRENT_PRODUCT = 'CLEAR_CURRENT_PRODUCT',
  SET_CLOUD = 'SET_CLOUD',
  SET_IAM_POLICY = 'SET_IAM_POLICY',
  SET_S3_STATUS = 'SET_S3_STATUS',
  SET_IF_AWS_CUSTOM_CATALOG_UPLOAD = 'SET_IF_AWS_CUSTOM_CATALOG_UPLOAD',
  SET_G2_REVIEW = 'SET_G2_REVIEW',
}

export type PartnerIAMPolicy = {
  env: 'production' | 'staging'
  value: string
}

export enum PartnerType {
  User = 'user',
  Admin = 'admin',
}

export type G2ReviewResponseType = {
  id: string
  completed: boolean
  openedAt: string
  reviewType: string
  partnerId: string
}

export const getPartner =
  (partnerId: string, partnerObjectType: PartnerType = PartnerType.User) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.PARTNER_DATA))
    try {
      const { data } = await getPartnerDataFromIdentityService(
        partnerId,
        partnerObjectType === PartnerType.Admin
      )
      await dispatch(
        actionTypeWrapper(
          partnerObjectType,
          setPartner({
            ...data,
            websiteUrl: data.company_website,
            industry: data.company_industry,
          })
        )
      )
    } catch (err: unknown) {
      const errMess = getErrorMessages([RequestFailureMessage])(
        (err as AxiosError<ErrorResponse>).response
      )
      await dispatch(
        actionTypeWrapper(partnerObjectType, getPartnerFailed(errMess))
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
      await dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    } finally {
      dispatch(stopLoading(LoadingTypes.PARTNER_DATA))
    }
  }

export const getG2Review =
  (partnerId: string, partnerObjectType: PartnerType = PartnerType.User) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.G2_REVIEW))
    try {
      const G2ReviewData = await getG2ReviewData(partnerId)

      const completedReview = G2ReviewData?.filter(
        (review: G2ReviewResponseType) =>
          review.reviewType === 'G2' && review.completed
      )[0]

      if (isEmpty(completedReview)) {
        dispatch(updateG2ReviewStatus({ isG2Review: false, G2ReviewList: G2ReviewData }, partnerObjectType))
      } else {
        dispatch(updateG2ReviewStatus({ isG2Review: true, G2ReviewList: G2ReviewData }, partnerObjectType))
      }
    } catch (err: unknown) {
      const errMess = getErrorMessages([RequestFailureMessage])(
        (err as AxiosError<ErrorResponse>).response
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
      await dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    } finally {
      dispatch(stopLoading(LoadingTypes.G2_REVIEW))
    }
  }
export const updateSelectedPartnerId =
  (partner_id: string, partnerObjectType: PartnerType = PartnerType.Admin) =>
  (dispatch: Dispatch) => {
    dispatch(
      actionTypeWrapper(partnerObjectType, {
        type: PartnerActionTypes.SET_SELECTED_PARTNER_ID,
        payload: partner_id,
      })
    )
  }
export const setPartner = (data: Partial<PartnerData>) => ({
  type: PartnerActionTypes.SET_PARTNER,
  payload: camelize(data),
})

export const setCloudForPartner = (
  partnerType: PartnerType,
  cloudProvider: CloudProvider | CloudType
) =>
  actionTypeWrapper(partnerType, {
    type: PartnerActionTypes.SET_CLOUD,
    payload: cloudProvider,
  })
export const getPartnerFailed = (err: unknown) => ({
  type: PartnerActionTypes.GET_PARTNER_FAILED,
  payload: err,
})
export const partnerLoading = () => ({
  type: PartnerActionTypes.PARTNER_LOADING,
})

export const setCRMId = (crmId: string) => ({
  type: PartnerActionTypes.SET_CRM_ID,
  payload: crmId,
})

export type G2ReviewData = {
  G2Review: boolean, 
  G2ReviewList?: G2ReviewResponseType[]
}

export const setG2Review = ({G2Review, G2ReviewList}: G2ReviewData) => ({
  type: PartnerActionTypes.SET_G2_REVIEW,
  payload: { G2Review, G2ReviewList },
})

export const sethasAWSCustomCatalog = (hasAWSCustomCatalog: boolean) => ({
  type: PartnerActionTypes.SET_IF_AWS_CUSTOM_CATALOG_UPLOAD,
  payload: hasAWSCustomCatalog,
})

export const updateCrm = (
  crm_id: string,
  partnerObjectType: PartnerType = PartnerType.User
) => actionTypeWrapper(partnerObjectType, setCRMId(crm_id))

export const setLastSync = (data: syncResponse) => ({
  type: PartnerActionTypes.SET_LAST_SYNC,
  payload: { data: camelize(data) },
})
export const setStatus = (
  status: TestButtonStatus,
  env: 'production' | 'staging',
  partnerObjectType: PartnerType = PartnerType.User
) =>
  actionTypeWrapper(partnerObjectType, {
    type: PartnerActionTypes.SET_S3_STATUS,
    payload: { status, env },
  })
export const cleanupPartner =
  (partnerType: PartnerType) => (dispatch: Dispatch) => {
    dispatch(
      actionTypeWrapper(partnerType, {
        type: PartnerActionTypes.PARTNER_CLEANUP,
      })
    )
  }

export const updatePartner =
  (
    partnerId: string,
    partnerObjectType: PartnerType,
    dataPartner: Partial<PartnerData>,
    infoData: Record<string, string>,
    handleNext?: () => void
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    const partnerData = getState().PartnerData[partnerObjectType].partnerData
    try {
      const res = await patchPartnerIdentityService(partnerId, dataPartner)
      if (handleNext) {
        handleNext()
      }
      await dispatch(
        actionTypeWrapper(
          partnerObjectType,
          setPartner({ ...partnerData, ...res.data, partnerOwner: infoData })
        )
      )
    } catch (err) {
      dispatch(
        updateAppAlert({
          message: RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
      dispatch(stopLoading(LoadingTypes.GENERAL))
      return err
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const updatePartnerIAMPolicy =
  (
    partnerId: string,
    partnerObjectType: PartnerType,
    partnerIAMPolicy: PartnerIAMPolicy
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SUBMIT_IAM_POLICY))
    dispatch(setStatus('standBy', partnerIAMPolicy.env))
    try {
      const parsedPolicy = JSON.parse(partnerIAMPolicy.value)
      const payload: PatchPartnerAWSDetails = {
        iam_policies: [
          {
            environment: partnerIAMPolicy.env,
            policy: parsedPolicy,
          },
        ],
      }
      await patchPartnerAWSDetails(partnerId, payload)
      dispatch(
        actionTypeWrapper(partnerObjectType, {
          type: PartnerActionTypes.SET_IAM_POLICY,
          payload: partnerIAMPolicy,
        })
      )
    } catch (err) {
      const globalState = getState()
      errorLogger({ globalState })(
        new Error('something went wrong while saving IAM policy')
      )
      const axiosError = err as AxiosError<ErrorResponse>
      const errorMsg = `Failed to save IAM Policy: ${getErrorMessages([
        RequestFailureMessage,
      ])(axiosError?.response)}`
      dispatch(
        updateAppAlert({
          message: errorMsg,
          messageType: 'ERROR',
        })
      )

      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SUBMIT_IAM_POLICY))
    }
  }

export const toggleSync =
  (
    partnerId: string,
    partnerData: ToggleSyncData,
    partnerObjectType: PartnerType = PartnerType.User
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    dispatch(partnerLoading())
    try {
      const { data } = await patchPartnerEngineService(
        partnerId,
        snakeize(partnerData)
      )
      const response = await dispatch(
        actionTypeWrapper(partnerObjectType, setPartner(data))
      )

      dispatch(
        updateAppAlert({
          message: response.payload.syncPaused
            ? 'Sync turned Off'
            : 'Sync turned On',
          messageType: 'SUCCESS',
          autoClose: false,
        })
      )
    } catch (err) {
      dispatch(
        updateAppAlert({
          message: SyncStatusFail,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const checkS3Connection =
  (env: 'production' | 'staging', partnerId?: string, showAlerts?: boolean) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const globalState = getState()
    if (partnerId) {
      if (showAlerts) {
        dispatch(startLoading(LoadingTypes.S3_CONNECTION))
      } else {
        dispatch(setStatus('loading', env))
      }

      try {
        const response = await getCheckS3Connection(partnerId, env)
        const connectionFlag: ConnectionJson = response.data
        const { success, error } = connectionFlag
        if (showAlerts) {
          if (success) {
            dispatch(
              updateAppAlert({
                message: S3ConnectionSuccessMessage,
                autoClose: true,
                messageType: 'SUCCESS',
              })
            )
          } else {
            const errorMessage = error
              ? S3ConnectionFailureMessage + ` ${error}`
              : S3ConnectionFailureMessage
            dispatch(
              updateAppAlert({
                message: errorMessage,
                autoClose: true,
                messageType: 'ERROR',
              })
            )
          }
        } else {
          if (success) {
            dispatch(setStatus('success', env))
          } else {
            dispatch(setStatus('error', env))
          }
        }
      } catch (error: unknown) {
        if (showAlerts) {
          const errMessage = getErrorMessages([PartnerUpdateError])(
            (error as AxiosError<ErrorResponse>).response
          )
          errorLogger({ globalState })(error as Error)
          dispatch(
            updateAppAlert({
              message: errMessage,
              autoClose: true,
              messageType: 'ERROR',
            })
          )
        } else {
          dispatch(setStatus('error', env))
        }
      } finally {
        dispatch(stopLoading(LoadingTypes.S3_CONNECTION))
      }
    }
  }

export const getPartnerLastSync =
  (partnerId: string, partnerObjectType: PartnerType = PartnerType.User) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { data } = await fetchLastSyncDetails(partnerId)
      await dispatch(actionTypeWrapper(partnerObjectType, setLastSync(data)))
    } catch (err) {
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
      console.warn(err)
    }
  }

export const gethasAWSCustomCatalog =
  (partnerId: string, partnerObjectType: PartnerType = PartnerType.User) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.PRODUCTS_AND_SERVICES_CHECK))
    try {
      const { data } = await getCloudConfiguration(partnerId)
      if (!isEmpty(data?.cloud_config)) {
        await dispatch(
          actionTypeWrapper(partnerObjectType, sethasAWSCustomCatalog(true))
        )
      } else {
        await dispatch(
          actionTypeWrapper(partnerObjectType, sethasAWSCustomCatalog(false))
        )
      }
    } catch (err) {
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
      console.warn(err)
      await dispatch(
        actionTypeWrapper(partnerObjectType, sethasAWSCustomCatalog(false))
      )
    } finally {
      dispatch(stopLoading(LoadingTypes.PRODUCTS_AND_SERVICES_CHECK))
    }
  }

export interface UpdatePartnerData {
  dataPartner: PartnerData
  infoData: Record<string, string>
}

export interface UpdateOnboardingData {
  dataOnboarding: OnboardingState
}
export interface UpdateG2Review {
  completed: boolean
  reviewType?: 'G2'
  openedAt?:string
}

export const updatePartnerAndOnboarding =
  (
    partnerId: string,
    partnerObjectType: PartnerType,
    updatePartnerData: UpdatePartnerData,
    updateOnboardingData: UpdateOnboardingData,
    updateG2Review: UpdateG2Review
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { dataPartner, infoData } = updatePartnerData
    const { dataOnboarding } = updateOnboardingData
    const { companyName, isInternal } = dataPartner;
    const companyData = { companyName, isInternal }
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const [updatePartnerRes,updatedCompanyData, updateOnboardingRes, updatedCustomerReviewG2] =
        await Promise.all([
          patchPartnerEngineService(partnerId, snakeize(dataPartner)),
          patchPartnerIdentityService(partnerId, companyData),
          patchOnboarding(partnerId, snakeize(dataOnboarding)),
          putCustomerReviewG2(partnerId, updateG2Review),
        ])
      dispatch(
        actionTypeWrapper(
          partnerObjectType,
          setPartner({ ...updatePartnerRes.data,...updatedCompanyData.data, partnerOwner: infoData })
        )
      )
      dispatch(
        actionTypeWrapper(
          partnerObjectType,
          setOnboarding(camelize(updateOnboardingRes.data))
        )
      )

      const isG2Review = updatedCustomerReviewG2?.data?.completed || false
      dispatch(updateG2ReviewStatus({ isG2Review }, partnerObjectType))
      

      dispatch(
        updateAppAlert({
          message: PartnerUpdateSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error) {
      const errMessage = getErrorMessages([PartnerUpdateError])(
        (error as AxiosError<ErrorResponse>).response
      )
      dispatch(
        updateAppAlert({
          message: errMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setCurrentProduct = (product?: ProductType) => ({
  type: PartnerActionTypes.SET_CURRENT_PRODUCT,
  payload: product,
})

export const clearCurrentProduct = () => ({
  type: PartnerActionTypes.CLEAR_CURRENT_PRODUCT,
})

export const setPartnerUser = actionTypeWrapperCurried(PartnerType.User)(
  setPartner
)
export const setPartnerAdmin = actionTypeWrapperCurried(PartnerType.Admin)(
  setPartner
)
export const updateG2ReviewStatus = (
  G2Review: { isG2Review: boolean, G2ReviewList?: G2ReviewResponseType[] },
  partnerObjectType: PartnerType = PartnerType.Admin
) => actionTypeWrapper(partnerObjectType, setG2Review({G2Review: G2Review.isG2Review, G2ReviewList: G2Review?.G2ReviewList}))
