import React, { useEffect, useMemo, useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { NavigationRoutesType } from '@labrav/react-components/lib/@types/components/SidePanel/SidePanel'
import { SidePanel } from '@labrav/react-components'

import { ProductType } from '../../modules/partner/reducer'
import { ActiveProducts, Product } from '../../modules/partner/action'
import {
  ProductRoutingMap,
  ProductRoutingMapShort,
} from '../../utils/productRoutingMap'

import { useSelector } from '../../../store'
import { UserProfile } from '../../modules/userProfile/action'
import { Role } from '../../utils/roles'
import {
  CloudFaaSNavigationRoutes,
  FlyOutNavigationRoutes,
  footerRoutes,
  footerRoutesForAdmin,
  OppSyncNavigationRoutes,
  productSelectorData,
} from './SidePanelWrapperRoutes'
import { useOnboardingComplete } from '../../utils/Hooks/onBoardingComplete'
import { OnboardingState } from '../../modules/onboarding/reducer'
import { isEmpty } from 'lodash'
import { UserProfileState } from '../../modules/userProfile/reducer'
import { errorLogger } from '../../utils/errorLogger'
import { Account } from '../../modules/cmcm/types'
import { useFlagValue } from '@labrav/flags'
import { useAuth0 } from '@auth0/auth0-react'

interface SidePanelWrapperProps {
  isNotInProductSelection: boolean
  currentProduct?: ProductType
  activeProducts: Product[]
}
export const SidePanelWrapper: React.FC<SidePanelWrapperProps> = ({
  isNotInProductSelection,
  activeProducts,
  currentProduct,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeLink, setActiveLink] = React.useState('')

  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')
  const { logout: auth0Logout, isAuthenticated, user: auth0User } = useAuth0()

  const { onboardingComplete } = useOnboardingComplete()
  const activeProductsFromUser = useSelector<ActiveProducts | undefined>(
    state => state.PartnerData.user.partnerData?.activeProducts
  )
  const [currentRoutes, setCurrentRoutes] = useState<NavigationRoutesType[]>([])
  const [userName, setUserName] = useState<string>('')
  const { userProfile } = useSelector<UserProfileState>(
    state => state.userProfile
  )
  const globalState = useSelector(state => state)
  const { value: isLabraCustomerOnboarding } = useFlagValue(
    'labraCustomerOnboarding'
  )
  useEffect(() => {
    setUserName(auth0User?.given_name + ' ' + auth0User?.family_name)
  }, [userProfile, auth0Enabled, auth0User, isAuthenticated])
  const logout = () => {
    try {
      localStorage.removeItem('onboardingWelcome')
      localStorage.removeItem('cloudType')
    } catch (err) {
      console.warn('localStorage is not available.')
      errorLogger({ globalState })(err as Error)
      return undefined
    }

    localStorage.removeItem('auth0-access-token')
    auth0Logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const product = currentProduct || activeProducts[0]

  const footerSubMenuRoutes: NavigationRoutesType[] = [
    {
      key: 7,
      path: '/user/profile',
      name: 'Account Information',
      disabled: false,
    },

    {
      key: 9,
      path: undefined,
      name: 'Logout',
      onClick: logout,
      disabled: false,
    },
  ]

  const user = useSelector<UserProfile | undefined>(
    state => state.userProfile.userProfile
  )
  const { awsSettingStatus } = useSelector<OnboardingState>(
    state => state.onboarding.user
  )

  const isInitiated = awsSettingStatus && awsSettingStatus !== 'NOT_INITIATED'

  const isAdmin =
    user?.roles?.some(r =>
      [Role.ADMIN, Role.SUPER_ADMIN, Role.ACCOUNT_EXECUTIVE].includes(r)
    ) || false

  const navigateRoute = (path: string) => {
    if (path) {
      navigate(path)
    }
  }

  const routes = {
    oppsync: OppSyncNavigationRoutes,
    cloudfaas: CloudFaaSNavigationRoutes,
    flyout: FlyOutNavigationRoutes,
  }
  const isFlyOutActive = activeProductsFromUser?.includes('flyout')
  const isOppSyncAndOnboarded =
    !!product && product === 'oppsync' && onboardingComplete
  const productSelector = useMemo(() => {
    const prodSel = { ...productSelectorData }
    if (product) {
      prodSel.active = ProductRoutingMapShort[product]
      if (isOppSyncAndOnboarded || (isFlyOutActive && product !== 'oppsync')) {
        setCurrentRoutes(routes[product])
      } else {
        setCurrentRoutes([])
      }
    }
    return prodSel
  }, [product, isFlyOutActive, isOppSyncAndOnboarded])

  const activeProductsWithSelectorName = activeProducts.map(
    a => ProductRoutingMap[a]
  )

  if (productSelector.options.length > 0) {
    productSelector.options.map(o => {
      const productName =
        Object.keys(ProductRoutingMapShort).find(
          key => ProductRoutingMapShort[key as Product] === o.name
        ) || ''

      activeProductsWithSelectorName.includes(productName) &&
      (isInitiated === true || isFlyOutActive)
        ? (o.link = `/${productName}`)
        : (o.link = `/product-selection?product=${productName}`)
    })
  }

  const showProductSelectorAndOptions =
    isFlyOutActive ||
    (isOppSyncAndOnboarded && activeProductsFromUser?.includes(product)) ||
    (!isNotInProductSelection && onboardingComplete)

  const finalFooterRoutes = !isAdmin ? footerRoutes : footerRoutesForAdmin

  useEffect(() => {
    const pathName = location.pathname.includes('settings')
      ? '/settings'
      : location.pathname
    setActiveLink(pathName)
  }, [location.pathname])

  if (isFlagsLoading) return <></>
  return (
    <SidePanel
      activeLink={activeLink}
      routes={showProductSelectorAndOptions && !isAdmin ? currentRoutes : []}
      navigateRoute={navigateRoute}
      footerRoutes={finalFooterRoutes}
      footerSubMenuRoutes={footerSubMenuRoutes}
      selectorData={productSelector}
      showProductSelector={!isAdmin && !isEmpty(productSelector.active)}
      showSelectorOptions={!isAdmin && !isEmpty(productSelector.active)}
    />
  )
}
