import React, { useEffect, useState } from 'react'
import { useStyles } from './ProductSelector.styles'
import { ProductCard } from '../ProductCard/ProductCard'
import { ProductDetails } from '../../utils/ProductDetails'
import { CloudProviderSelector } from '../../../oppsync/components/CloudProvider/CloudProvider'
import {
  Product,
  CloudProvider,
  setCurrentProduct,
} from '../../modules/partner/action'
import { useDispatch, useSelector } from '../../../store'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'
import { ProductRoutingMap } from '../../utils/productRoutingMap'
import { ProductType } from '../../modules/partner/reducer'

import Grid from '@material-ui/core/Grid'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useFlagValue } from '@labrav/flags'
import { LabraCloudProviderSelector } from '../../../oppsync/components/CloudProvider/LabraCloudProvider'
import { UserProfileState } from '../../modules/userProfile/reducer'

export const ProductSelector: React.FC<{
  onSelect: (product: Product, cloudProvider: CloudProvider) => void
  productDetails: ProductDetails[]
}> = ({ onSelect, productDetails }) => {
  const dispatch = useDispatch()
  const [product, setProduct] = useState<{ id: Product; bgColor: string }>()
  const { value: isLabraCustomerOnboarding } = useFlagValue(
    'labraCustomerOnboarding'
  )
  const { value: isCloudfaasFeatEnabled } =
  useFlagValue('cloudfaasFeat') 

  const classes = useStyles(isLabraCustomerOnboarding)()
  const { userProfile } = useSelector<UserProfileState>(
    state => state.userProfile
  )
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'))
  useEffect(() => {
    if (product?.id) {
      dispatch(
        actionTypeWrapper(
          'user',
          setCurrentProduct(ProductRoutingMap[product.id] as ProductType)
        )
      )
      // auto scoll to cloud-selector
      const continueButton = document.querySelector('[data-testid="cloud-provider-continue-button"]');
      if (continueButton) {
        continueButton.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [product])

  return (
    <div data-testid="product-selection-page" className={classes.root}>
      <div className={classes.container}>
        {!isLabraCustomerOnboarding ? (
          <div className={classes.heading} data-testid="select-product-heading">
            Select a product
          </div>
        ) : (
          <div
            className={classes.welcomeTitle}
            data-testid="select-product-heading-with-name"
          >
            {userProfile?.givenName &&
              `Hello ${userProfile.givenName}! Please select a product to start onboarding`}
          </div>
        )}
        <div className={classes.productsContainer}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              {productDetails.map(prod => (
                <>
                  <Grid key={prod.id} item xs={12} md={12} lg={4} xl={4}>
                    <ProductCard
                      key={prod.productName}
                      product={prod}
                      selectedProduct={product?.id}
                      handleCloudProviderClick={() =>
                        setProduct({
                          id: prod.id,
                          bgColor: prod.headerStripeColor,
                        })
                      }
                    />

                    {smallScreen && (
                      <div
                        className={classes.cloudSelectorContainer}
                        data-testid="cloud-selector-mobile"
                      >
                        {' '}
                        {product !== undefined &&
                          product.id === prod.id &&
                          (isLabraCustomerOnboarding ? (
                            <LabraCloudProviderSelector
                              onSelect={cloudProvider => {
                                onSelect(product?.id, cloudProvider)
                              }}
                            />
                          ) : (
                            <CloudProviderSelector
                              productBgColor={product?.bgColor}
                              onSelect={cloudProvider => {
                                onSelect(product?.id, cloudProvider)
                              }}
                            />
                          ))}
                      </div>
                    )}
                  </Grid>
                </>
              ))}
            </Grid>

            {!smallScreen && (
              <div
                className={`${classes.cloudSelectorContainer} ${
                  (isCloudfaasFeatEnabled && product?.id === 'cloudfaas')  ? classes.rightCloudSelectorContainer : ''
                }` }
                data-testid="cloud-selector-desktop"
                style={{ paddingTop: isLabraCustomerOnboarding ? '32px' : '' }}
              >
                {' '}
                {product !== undefined &&
                  (isLabraCustomerOnboarding ? (
                    <LabraCloudProviderSelector
                      productId={product?.id}
                      onSelect={cloudProvider => {
                        onSelect(product?.id, cloudProvider)
                      }}
                    />
                  ) : (
                    <CloudProviderSelector
                      productBgColor={product?.bgColor}
                      onSelect={cloudProvider => {
                        onSelect(product?.id, cloudProvider)
                      }}
                    />
                  ))}
              </div>
            )}
          </Grid>
        </div>
      </div>
    </div>
  )
}
