import { useFlag, useFlagValue } from '@labrav/flags'
import { useCallback, useState } from 'react'
import { Navigate } from 'react-router-dom'
import {
  Product,
  CloudProvider,
  updatePartner,
  PartnerData,
  PartnerType,
  setCloudForPartner,
} from '../../modules/partner/action'
import { useDispatch, useSelector } from '../../../store'
import { productDetailsEnabled as productDetails } from '../../utils/ProductDetails'
import { ProductSelector } from './ProductSelector'
import { sellerCreation } from '../../../flyout/modules/seller/action'
import { HeaderAlertComponent } from '../HeaderAlert/HeaderAlert'
import { formattedActiveProducts } from '../../utils/formattedProducts'

export const ProductSelectorWrapper = () => {
  const dispatch = useDispatch()
  const [selectedProduct, setSelectedProduct] = useState<{
    product: Product
    cloudProvider: CloudProvider
  }>()

  const { value: labraFlyoutOnboarding, loading: flyoutOnboardingLoading } =
    useFlagValue('labraFlyoutOnboarding')
  const { value: isLabraCustomerOnboarding, loading: onboardingLoading } =
    useFlagValue('labraCustomerOnboarding')
    const { value: isCloudfaasFeatEnabled } =
    useFlagValue('cloudfaasFeat') 
  const partner = useSelector<PartnerData>(
    state => state.PartnerData.user.partnerData!
  )

  const handleNext =
    (product: Product, cloudProvider: CloudProvider) => async () => {
      await setSelectedProduct({
        product,
        cloudProvider,
      })
    }
  const updatePartnerData = async (
    activeProducts: Product[] | string[],
    product: Product,
    cloudProvider: CloudProvider
  ) => {
    const formattedProducts = formattedActiveProducts(
      activeProducts,
      cloudProvider
    )

    await dispatch(
      updatePartner(
        partner.partnerId,
        PartnerType.User,
        {
          activeProducts: formattedProducts,
        },
        {},
        handleNext(product, cloudProvider)
      )
    )
  }
  const handleProductSelection = useCallback(
    async (product: Product, cloudProvider: CloudProvider) => {
      if (partner.partnerId && product && cloudProvider) {
        await dispatch(setCloudForPartner(PartnerType.User, cloudProvider))

        const activeProducts = (partner.activeProducts || []).concat(product)
        if (
          product === 'flyout' &&
          cloudProvider === CloudProvider.AWS &&
          !partner.activeProducts?.includes('flyout')
        ) {
          await dispatch(
            sellerCreation({
              partnerId: partner.partnerId,
              cloudProvider: CloudProvider.AWS,
              activeProducts,
              product,
              updatePartnerData,
            })
          )
        } else {
          updatePartnerData(activeProducts, product, cloudProvider)
        }
      }
    },
    [partner]
  )

  if (
    selectedProduct &&
    selectedProduct.product === 'flyout' &&
    labraFlyoutOnboarding
  ) {
    return (
      <Navigate
        replace
        to={`/${selectedProduct.product}/onboarding/aws-requirements?newFirst=true`}
      />
    )
  }
  if (
    selectedProduct &&
    selectedProduct.product === 'oppsync' &&
    isLabraCustomerOnboarding
  ) {
    return <Navigate replace to={`/${selectedProduct.product}/onboarding`} />
  }
  if (
    selectedProduct &&
    selectedProduct.product === 'cloudfaas' &&
    isCloudfaasFeatEnabled
  ) {
    return <Navigate replace to={`/${selectedProduct.product}/ftr`} />
  }
  if (
    selectedProduct &&
    ((selectedProduct.product === 'oppsync' && !isLabraCustomerOnboarding) ||
      (selectedProduct.product === 'flyout' && !labraFlyoutOnboarding))
  ) {
    return (
      <Navigate
        replace
        to={`/settings/${
          selectedProduct.product
        }/cloud/${selectedProduct.cloudProvider.toLowerCase()}`}
      />
    )
  }

  if (onboardingLoading || flyoutOnboardingLoading) {
    return <></>
  } else {
    const updatedProductDetails = productDetails?.map((productItem) => {
      if (productItem.id === 'cloudfaas' && isCloudfaasFeatEnabled) {
        return { ...productItem, productAvailable: true }
      }
      return productItem
    })

    return (
      <>
        <HeaderAlertComponent />
        <ProductSelector
          productDetails={updatedProductDetails}
          onSelect={handleProductSelection}
        />
      </>
    )
  }
}
