import { AxiosResponse } from 'axios'
import { camelize } from 'casing'
import { RootState, AppDispatch } from '../../../store'

import {
  ReferralObject,
  SortDirection,
  AwsProducts,
  ApiResponseProduct,
  PartnerCentralUsers,
} from './reducer'

import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { isAdminRole } from '../../../common/utils/isAdminRole'
import { PartnerType } from '../../../common/modules/partner/action'
import {
  fetchMetrics,
  fetchReferrals,
  fetchAwsProductsAndSolutions,
  fetchPartnerCentralUsers,
  fetchCRMfiels,
} from '../../../common/api/CoSell'
import { getReferralObjectTransformed } from '../../../common/utils/CoSellHelpers/helperFunctions'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { getErrorMessages } from '../../../common/utils/error'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { errorLogger } from '../../../common/utils/errorLogger'
import { ProductType } from '../../../common/modules/partner/reducer'
import {
  FormField,
  SelectorOptions,
} from '@labrav/react-components/lib/@types/types/forms'

export enum COSELL_ACTIONS {
  SET_REFERRALS = 'SET_REFERRALS',
  SET_REFERRALS_METRICS = 'SET_REFERRALS_METRICS',
  SET_AWS_PRODUCTS = 'FETCH_AWS_PRODUCTS',
  SET_PARTNER_CENTRAL_USER_DETAILS = 'SET_PARTNER_CENTRAL_USER_DETAILS',
  SET_SOLUTION_OFFERED_OPTIONS = 'SET_SOLUTION_OFFERED_OPTIONS',
  SET_REFERRAL_FORM_FIELDS = 'SET_REFERRAL_FORM_FIELDS',
  CLEAR_REFERRALS_DATA = 'CLEAR_REFERRALS_DATA',
  SET_SHOW_ALL_FIELDS = 'SET_SHOW_ALL_FIELDS',
  SET_CATEGORIES_WITH_NO_FIELDS = 'SET_CATEGORIES_WITH_NO_FIELDS',
}

export type ReferralFormField = {
  dataType: string
  disableInCreation?: boolean
  disableInUpdate?: boolean
  hideInCreation?: boolean
  id: string
  info?: string
  isRequired?: boolean
  maxLength?: number
  placeholder?: string
  title: string
}

export const setReferrals = (referralObject: {
  referral: ReferralObject[]
  count: number
  pageSize: number
  pageNumber: number
  hasMore: boolean
}) => ({
  type: COSELL_ACTIONS.SET_REFERRALS,
  payload: referralObject,
})

export const clearReferralsData = () => ({
  type: COSELL_ACTIONS.CLEAR_REFERRALS_DATA,
})

export const setAwsProducts = (awsProducts: SelectorOptions[]) => ({
  type: COSELL_ACTIONS.SET_AWS_PRODUCTS,
  payload: awsProducts,
})

export const setPartnerCentralUsersData = (data: PartnerCentralUsers[]) => ({
  type: COSELL_ACTIONS.SET_PARTNER_CENTRAL_USER_DETAILS,
  payload: data,
})

export const setReferralsMetrics = (
  referralMetrics: Record<string, unknown>
) => ({
  type: COSELL_ACTIONS.SET_REFERRALS_METRICS,
  payload: referralMetrics,
})

export const setSolutionOfferedOptions = (
  solutionOfferedOptions: SelectorOptions[]
) => ({
  type: COSELL_ACTIONS.SET_SOLUTION_OFFERED_OPTIONS,
  payload: solutionOfferedOptions,
})

export const setReferralFormFields = (
  referralFormFields: ReferralFormField[]
) => ({
  type: COSELL_ACTIONS.SET_REFERRAL_FORM_FIELDS,
  payload: referralFormFields,
})

export const setIsShowRequiredFieldsOnly = (showFields: {
  isShowRequiredFieldsOnly: boolean
}) => ({
  type: COSELL_ACTIONS.SET_SHOW_ALL_FIELDS,
  payload: showFields,
})

export const setCategoriesWithNoFields = (categories: string[]) => ({
  type: COSELL_ACTIONS.SET_CATEGORIES_WITH_NO_FIELDS,
  payload: categories,
})

export interface ReferralsContent {
  referral_metadata: Record<string, unknown>
  referral_object: Record<string, unknown>
}
export interface ReferralsResponse {
  referrals: ReferralsContent[]
  count: number
  page_number: number
  page_size: number
}

export type CosellReferralType = 'opportunity' | 'lead'
export type CoseelReferralBy = 'ace' | 'crm'
export const fetchReferralsData =
  ({
    productId,
    pageSize = 10,
    pageNumber,
    referralType = 'opportunity',
    referralBy = 'ace',
    sortBy = 'created_at',
    sortOrder = 'desc',
    keepPrevious = false,
    fetchMoreData = false,
  }: {
    productId: ProductType
    pageSize?: number
    pageNumber?: number
    referralType?: CosellReferralType
    referralBy?: CoseelReferralBy
    sortBy?: string
    sortOrder?: SortDirection
    keepPrevious?: boolean
    fetchMoreData?: boolean
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    !fetchMoreData &&
      dispatch(startLoading(LoadingTypes.COSELL_FETCH_REFERRALS))

    try {
      const state = getState()
      const currentReferrals =
        state.cosell[productId].referralObjects?.referrals || []
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const partnerId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      const response = await fetchReferrals(
        partnerId,
        pageSize,
        pageNumber,
        referralType,
        referralBy,
        sortBy,
        sortOrder
      )

      const referralsObject = getReferralObjectTransformed(response?.data)
      const referrals = keepPrevious
        ? [...currentReferrals, ...referralsObject]
        : referralsObject
      await dispatch(
        actionTypeWrapper(
          productId,
          setReferrals({
            referral: referrals,
            count: response?.data?.count || 0,
            pageNumber: response?.data?.page_number || 1,
            pageSize: response?.data?.page_size || 10,
            hasMore: referralsObject.length >= pageSize || pageNumber === 1,
          })
        )
      )
    } catch (e) {
      const errorMessage = getErrorMessages(['Unable to fetch referrals'])(
        e as AxiosResponse<ErrorResponse>
      )

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.COSELL_FETCH_REFERRALS))
    }
  }

export const fetchReferralsMetrics =
  (productId: ProductType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.COSELL))

    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const partnerId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      const response = await fetchMetrics(partnerId)

      await dispatch(
        actionTypeWrapper(
          productId,
          setReferralsMetrics(camelize(response.data))
        )
      )
    } catch (e) {
      const errorMessage = getErrorMessages(['Unable to fetch referrals'])(
        e as AxiosResponse<ErrorResponse>
      )

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.COSELL))
    }
  }

export const fetchAwsProducts =
  (productId: ProductType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.COSELL))

    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const partnerId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      const response = await fetchAwsProductsAndSolutions(partnerId)
      const awsProducts: AwsProducts[] =
        response.data.cloud_config.aws_products.map(
          (item: ApiResponseProduct) => ({
            ProductCode: item.aws_product_code,
            productName: item.product_name,
            productDescription: item.product_description,
            productFamily: item.product_family,
          })
        )

      const productOptionsValues = awsProducts.map((item: AwsProducts) => {
        return { label: item.productName, value: item.ProductCode }
      }) as SelectorOptions[]
      await dispatch(
        actionTypeWrapper(productId, setAwsProducts(productOptionsValues))
      )
    } catch (e) {
      const errorMessage = getErrorMessages(['Unable to fetch aws products'])(
        e as AxiosResponse<ErrorResponse>
      )
      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.COSELL))
    }
  }

export const fetchPartnerCentralUsersData =
  (productId: ProductType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.COSELL_PARTNER_CENTRAL_USERS_DATA))

    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const partnerId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      const response = await fetchPartnerCentralUsers(partnerId)

      await dispatch(
        actionTypeWrapper(
          productId,
          setPartnerCentralUsersData(camelize(response.data))
        )
      )
    } catch (e) {
      const errorMessage = getErrorMessages(['Unable to fetch aws products'])(
        e as AxiosResponse<ErrorResponse>
      )
      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.COSELL_PARTNER_CENTRAL_USERS_DATA))
    }
  }

export const fetchSolutionOffered =
  (productId: ProductType, crmId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.COSELL))

    try {
      const response = await fetchCRMfiels(crmId)
      const solutions = response.data.find(
        (r: FormField) => r.id === 'Labra__Solution_offered__c'
      ).options as SelectorOptions[]

      await dispatch(
        actionTypeWrapper(productId, setSolutionOfferedOptions(solutions))
      )

      await dispatch(
        actionTypeWrapper(
          productId,
          setReferralFormFields(camelize(response.data))
        )
      )
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch solution offered',
      ])(e as AxiosResponse<ErrorResponse>)
      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.COSELL))
    }
  }
