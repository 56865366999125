import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTracker } from './Tracker/hook'
import { serviceEnvironment } from './constants'

export const useAnalytics = () => {
  const location = useLocation()
  const tracker = useTracker()

  useEffect(() => {
    if (serviceEnvironment === 'prd') {
      tracker?.page()
    }
  }, [location])
}
