import { StorefrontMetricsActionTypes } from './action'
import { Reducer } from 'redux'

export type StatRiseType = 'up' | 'down'
export interface ImpressionCount {
  keywordName: string
  totalCount?: number
  percentage?: number
  percentageStatus?: StatRiseType
  key?: string
}
export interface ButtonsRecords {
  keywordName: string
  clicks?: number
  clickPercentage?: number
  clickPercentageStatus?: StatRiseType
  formSubmissionPercentage?: number
  formSubmissionPercentageStatus?: StatRiseType
  key: string
}
export interface TrafficSource {
  count: number
  name: string
  percentage: number
  otherTrafficCounts: TrafficSource[]
}
export interface MetricsData {
  impressionCount: ImpressionCount[]
  buttonRecords: ButtonsRecords[]
  trafficSource: TrafficSource[]
  campaignsEfficiency: CampaignEfficiency[]
}
export interface CampaignEfficiency {
  count: number
  name: string
  percentage: number
  otherCampaignsCounts?: CampaignEfficiency[]
}
const initialState: MetricsData = {
  impressionCount: [],
  buttonRecords: [],
  trafficSource: [],
  campaignsEfficiency: [],
}

type actionType =
  | {
      type: StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_DATA
      payload: MetricsData
    }
  | {
      type: StorefrontMetricsActionTypes.CLEAR_STOREFRONT_METRICS_DATA
    }

export const reducer: Reducer<MetricsData, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case StorefrontMetricsActionTypes.CLEAR_STOREFRONT_METRICS_DATA:
      return initialState

    default: {
      return state
    }
  }
}
