import { camelize } from 'casing'
import { Dispatch } from 'redux'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import { SomeThingWentWrong } from '../../../../common/utils/messagesContants'
import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions'
import { SyncObjectType } from './reducer'
import { fetchPartnerObject } from '../../../../common/api'
import { AxiosError } from 'axios'
import { LoadingTypes } from '../../../../common/modules/loading/reducer'
import { RootState } from '../../../../store'
import { errorLogger } from '../../../../common/utils/errorLogger'
import { getErrorMessages } from '../../../../common/utils/error'

export enum PartnerObjectActionTypes {
  SHOW_SYNC_PANEL = 'SHOW_SYNC_PANEL',
  HIDE_SYNC_PANEL = 'HIDE_SYNC_PANEL',
  SET_SYNC_OBJECT_DATA = 'SET_SYNC_OBJECT_DATA',
  SET_PARTNER_OBJ_ERROR = 'SET_PARTNER_OBJ_ERROR',
  CLEAR_SYNC_OBJECT_DATA = 'CLEAR_SYNC_OBJECT_DATA',
}

export const getPartnerObject =
  (partnerId: string, objectId: string, objectType: CRMObjectType) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(showSyncPanel())
    dispatch(startLoading(LoadingTypes.GENERAL))
    return fetchPartnerObject(partnerId, objectId, 'sync_attempts', objectType)
      .then(res => {
        dispatch(setSyncObjectData(res.data))
      })
      .catch((error: unknown) => {
        const errorMessage = getErrorMessages([SomeThingWentWrong])(
          (error as AxiosError<ErrorResponse>).response
        )
        setSyncObjectError(errorMessage)
        dispatch(
          updateAppAlert({
            message: errorMessage,
            messageType: 'ERROR',
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      })
      .finally(() => {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      })
  }

export const setSyncObjectData = (object: SyncObjectType) => ({
  type: PartnerObjectActionTypes.SET_SYNC_OBJECT_DATA,
  payload: camelize(object),
})

export const clearSyncObjectData = () => ({
  type: PartnerObjectActionTypes.CLEAR_SYNC_OBJECT_DATA,
})
export const showSyncPanel = () => ({
  type: PartnerObjectActionTypes.SHOW_SYNC_PANEL,
})

export const hideSyncPanel = () => ({
  type: PartnerObjectActionTypes.HIDE_SYNC_PANEL,
})

export const setSyncObjectError = (error: unknown) => ({
  type: PartnerObjectActionTypes.SET_PARTNER_OBJ_ERROR,
  payload: error,
})
