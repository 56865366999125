import { AnyAction, Dispatch } from 'redux'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { errorLogger } from '../../../common/utils/errorLogger'
import { RootState } from '../../../store'
import { MetricsData } from './reducer'
import storefrontMetricsMock from '../../../mocks/storefrontMetricsMock.json'
import { camelize } from 'casing'

export enum StorefrontMetricsActionTypes {
  SET_STOREFRONT_METRICS_DATA = 'SET_STOREFRONT_METRICS_DATA',
  CLEAR_STOREFRONT_METRICS_DATA = 'CLEAR_STOREFRONT_METRICS_DATA',
}

export const getStorefrontMetricsData =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(clearStorefrontMetricsData())
    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const response = camelize(storefrontMetricsMock)
      await dispatch(setStorefrontMetricsData(response))
      await dispatch(
        updateAppAlert({
          message: '',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CREATE_TEMPLATE))
    }
  }
export const setStorefrontMetricsData = (metricsData: MetricsData) => {
  return {
    type: StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_DATA,
    payload: metricsData,
  }
}
export const clearStorefrontMetricsData = () =>
  ({
    type: StorefrontMetricsActionTypes.CLEAR_STOREFRONT_METRICS_DATA,
  } as unknown as AnyAction)
