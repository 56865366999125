import { ThemeProvider } from '@material-ui/core'
import { labravDefaultThemeV4 as theme } from '@labrav/react-components'
import { Provider } from 'react-redux'
import { getStore } from './store'
import { BrowserRouter } from 'react-router-dom'
import { FlagsProvider } from '@labrav/flags'
import {
  flagsCacheTTLSeconds,
  flagsUrl,
  serviceEnvironment,
  serviceId,
  viteSegmentApiWriteKey,
} from './common/utils/constants'
import { WithErrorBoundary } from './common/pages/components/RouteApp/RouteApp'
import OktaAndAuth0SecureApp from './common/pages/OktaAndAuth0SecureApp'
import devFlags from './flags/dev/flags.json'
import { useEffect } from 'react'
import { TrackerProvider } from './common/utils/Tracker/TrackerProvider'

function App() {
  const analytics = typeof window !== 'undefined' && window.analytics
  return (
    <Provider store={getStore()}>
      <ThemeProvider theme={theme}>
        <FlagsProvider
          env={serviceEnvironment}
          flagsUrl={flagsUrl}
          service={serviceId}
          cacheTtlSeconds={flagsCacheTTLSeconds}
          flagsJson={import.meta.env.DEV ? devFlags : undefined}
        >
          <TrackerProvider analytics={analytics || undefined}>
            <div className="App" style={{ height: '100%' }}>
              <div className="page" style={{ height: '100%' }}>
                <div className="content" style={{ height: '100%' }}>
                  <WithErrorBoundary>
                    <BrowserRouter basename={import.meta.env.VITE_PUBLIC_URL}>
                      <OktaAndAuth0SecureApp />
                    </BrowserRouter>
                  </WithErrorBoundary>
                </div>
              </div>
            </div>
          </TrackerProvider>
        </FlagsProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
