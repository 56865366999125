import { ListingCardCreationActionTypes } from './action'
import { Reducer } from 'redux'
import { v4 as uuid4 } from 'uuid'
import { IntegrationData } from '../templateCreation/reducer'

export interface BWAProductGet {
  [x: string]: {
    id: string
    data: Record<string, unknown>
    name: string
    createdAt: string
    productId: string
  }
}

export interface BWAProduct {
  uniqueId: string
  url: string
  isOpen: boolean
  id?: string
  data?: Record<string, unknown>
  name?: string
  isLoading: boolean
  createdAt?: string
  product_id?: string
}
export interface ListingCardData {
  integrationId?: string | null
  name: string
  cardAlignment: string
  logoPosition: string
  product: BWAProduct[]
  template: IntegrationData
}
export interface ListingCardInCreation {
  listingCardData: ListingCardData
}
export const initialState = {
  listingCardData: {
    integrationId: null,
    name: '',
    cardAlignment: 'left',
    logoPosition: 'inCard',
    product: [
      {
        uniqueId: String(uuid4()),
        url: '',
        isOpen: true,
        isLoading: false,
      },
    ],
    template: {},
  },
}

type actionType =
  | {
      type: ListingCardCreationActionTypes.SET_CREATE_LISTING_DATA
      payload: {
        integrationData: ListingCardData
      }
    }
  | {
      type: ListingCardCreationActionTypes.UPDATE_LISTING_CARD_DATA
      payload: {
        integrationId: string
        integrationData: Record<string, string>
      }
    }
  | {
      type: ListingCardCreationActionTypes.SET_SELECTED_TEMPLATE_ID
      payload: string
    }
  | {
      type: ListingCardCreationActionTypes.SET_TEMPLATE_ON_LISTING
      payload: IntegrationData
    }
  | {
      type: ListingCardCreationActionTypes.CLEAR_LISTING_CARD_DATA
    }

export const reducer: Reducer<ListingCardInCreation, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ListingCardCreationActionTypes.SET_CREATE_LISTING_DATA: {
      return {
        ...state,
        listingCardData: {
          ...state.listingCardData,
          ...action.payload,
        },
      }
    }
    case ListingCardCreationActionTypes.SET_SELECTED_TEMPLATE_ID: {
      return {
        ...state,
        listingCardData: {
          ...state.listingCardData,
          templateId: action.payload,
        },
      }
    }
    case ListingCardCreationActionTypes.SET_TEMPLATE_ON_LISTING: {
      const { id, name, ...rest } = action.payload
      return {
        ...state,
        listingCardData: {
          ...state.listingCardData,
          template: {
            id: id,
            name: name,
            data: { ...rest },
          },
        },
      }
    }
    case ListingCardCreationActionTypes.CLEAR_LISTING_CARD_DATA: {
      return {
        ...initialState,
        listingCardData: {
          ...initialState.listingCardData,
          product: [
            {
              uniqueId: String(uuid4()),
              url: '',
              isOpen: true,
              isLoading: false,
            },
          ],
        },
      }
    }
    default: {
      return state
    }
  }
}
